export const langArr = {
  hero: {
    en: `your partner for Artificial Intelligence, Data Science and Automation`,
    uk: 'ваш партнер у сфері штучного інтелекту, науки про дані та автоматизації',
  },
  'hero-discription': {
    en: `We use the newest GPT / LLM-based technologies 
& algorithms to drive innovation and provide state-of-the-art solutions for our clients and stay at the forefront of industry.`,
    uk: `Ми використовуємо новітні технології на основі GPT / LLM
& алгоритми, щоб стимулювати інновації та надавати найсучасніші рішення для наших клієнтів і залишатися в авангарді галузі.`,
  },
  'contact-us-hero-btn': {
    en: 'Contact Us',
    uk: `Зв'яжіться з нами`,
  },
  'nav-overview': {
    en: 'Overview',
    uk: 'Огляд',
  },
  'nav-services': {
    en: 'Services',
    uk: 'Послуги',
  },
  'nav-services-and-technologies': {
    en: 'Services & technologies',
    uk: 'Послуги та технології',
  },
  'nav-ml-ai-ds': {
    en: 'ML & AI Services',
    uk: 'ML & AI Сервіси',
  },
  'nav-collaboration': {
    en: 'Collaboration',
    uk: 'Співпраця',
  },
  'nav-our-contact': {
    en: 'Our contact',
    uk: 'Контакти',
  },
  'mob-nav-home': {
    en: 'Home',
    uk: 'Головна',
  },
  'mob-nav-overview': {
    en: 'Overview',
    uk: 'Огляд',
  },
  'mob-nav-services': {
    en: 'Services',
    uk: 'Послуги',
  },
  'mob-nav-collaboration': {
    en: 'Collaboration',
    uk: 'Співпраця',
  },
  'mob-nav-our-contacts': {
    en: 'Our contact',
    uk: 'Контакти',
  },
  'nav-submenu-services': {
    en: 'Services',
    uk: 'Послуги',
  },
  'nav-submenu-services-and-technologies': {
    en: 'Services & technologies',
    uk: 'Послуги та технології',
  },
  'nav-submenu-ml-ai-ds': {
    en: 'ML & AI Services',
    uk: 'ML & AI Сервіси',
  },
  'nav-contact-us': {
    en: 'Contact us',
    uk: "Зв'яжіться з нами",
  },
  'title-our-partners': {
    en: 'OUR PARTNERS',
    uk: 'Наші партнери',
  },
  'title-main-title': {
    en: 'We are experts in ML, AI &  Data Science',
    uk: 'Ми експерти в ML, AI &  Data Science',
  },
  overview__text: {
    en: `We use the newest GPT / LLM-based technologies 
        & algorithms to drive innovation and provide state-of-the-art solutions for our clients and stay at the forefront of industry.`,
    uk: 'Ми використовуємо найсучасніші технології та алгоритми, які базуються на GPT / LLM,   впроваджуємо інновації щоб надавати нашим клієнтам передові рішення та залишатися лідерами у нашій галузі.',
  },
  overview__title: {
    en: 'Who we are?',
    uk: 'Хто ми?',
  },
  'stats__name-consultants': {
    en: 'Consultants',
    uk: 'Консультантів',
  },
  'stats__name-consultants-discription': {
    en: 'Our consultants have over a decade experience in ML / DS',
    uk: 'Наші консультанти мають понад десятирічний досвід у ML/DS',
  },
  'stats__name-experience-accent': {
    en: '> 5 years',
    uk: '> 5 років',
  },
  'stats__name-experience': {
    en: 'of experience',
    uk: 'досвіду',
  },
  'stats__name-experience-discription': {
    en: 'MBIT Consultants LLC was founded in 2018 in Kyiv, Ukraine',
    uk: 'Компанія MBIT Consultants LLC заснована в 2018 році в Києві, Україна',
  },
  'stats__name-offices': {
    en: '2 Offices',
    uk: '2 Офіси',
  },
  'stats__name-offices-discription': {
    en: 'Since 2022, MBIT Estonia based in Tallinn is active.',
    uk: 'З 2022 року MBIT Estonia працює в Таллінні.',
  },
  'benefits-section__title': {
    en: 'Why MBIT',
    uk: 'Наші Переваги',
  },
  'benefits-nearshore-title': {
    en: 'Nearshore concept',
    uk: 'Nearshore концепція',
  },
  'benefits-nearshore-discription': {
    en: `Nearshore concept: Time difference marginal, European mindset, all our staff is business fluent in English`,
    uk: 'Різниця в часі незначна, а наш персонал має європейське мислення та добре володіє англійською мовою.',
  },
  'benefits-boost-title': {
    en: `IT Staff Boost`,
    uk: 'Розширення ІТ-персоналу',
  },
  'benefits-boost-discription': {
    en: `IT Staff Augmentation & fast scale-up of complete project teams`,
    uk: 'Збільшення ІТ-персоналу та швидке розширення проектних команд',
  },
  'benefits-cooperation-title': {
    en: `Cooperation`,
    uk: 'Співпраця',
  },
  'benefits-cooperation-discription': {
    en: `We work closely with European customers`,
    uk: 'Ми тісно співпрацюємо з європейськими клієнтами',
  },
  'benefits-culture-title': {
    en: `Culture`,
    uk: 'Культура',
  },
  'benefits-culture-discription': {
    en: `Innovative culture & going the extra mile for the customer`,
    uk: 'Культура інновацій та готовність робити все можливе для клієнта.',
  },
  projects__title: {
    en: `Successful project`,
    uk: 'Успішний проєкт',
  },
  projects__customer: {
    en: `Our Customer HELASOFT`,
    uk: 'Наш клієнт HELASOFT',
  },
  'projects__start-point': {
    en: `Starting point: Project in ML-NLP for the German chemical industry`,
    uk: 'Початкова точка: Проект ML-NLP для німецької хімічної промисловості',
  },
  projects__solution: {
    en: `Solution - HELACLOUD`,
    uk: 'Рішення - HELACLOUD',
  },
  'projects__solution-discription': {
    en: `The cloud solution built by us containing ML- NLP based extraction of a very complex document- the Safety Data Sheet for Chemicals.`,
    uk: 'Хмарне рішення, створене нами, містить вилучення на основі ML-NLP дуже складного документа – Паспорта безпеки хімічних речовин.',
  },
  projects__results: {
    en: `Results:`,
    uk: 'Результат:',
  },
  'projects__results-discription': {
    en: `Models based on BERT, LLM & GPT are used`,
    uk: 'Використовуються моделі на основі BERT, LLM і GPT',
  },
  'projects__results-interface': {
    en: `Frontend, Backend and interface to SAP was built successfully`,
    uk: 'Frontend, Backend та інтерфейс для SAP були успішно створені',
  },
  'contact-us-section__title': {
    en: `Lets Cooperate`,
    uk: 'Давайте співпрацювати',
  },
  'contact-us-callback__text': {
    en: `Connect with us`,
    uk: "Зв'яжіться з нами",
  },
  'contact-us-callback__info': {
    en: `Leave Your contact information for future Cooperation`,
    uk: 'Залиште свої контакти для майбутньої співпраці',
  },
  'contact-us-send': {
    en: `Send`,
    uk: 'Надіслати',
  },
  'section-collaboration-title': {
    en: `Collaboration`,
    uk: 'Співпраця',
  },
  'section-collaboration-kyiv': {
    en: `Nearshore Development in Kyiv / Ukraine`,
    uk: 'Nearshore Development в Києві / Україна',
  },
  'section-collaboration-kyiv-discription': {
    en: `Nearshore development, combines high-quality expertise with cost-effectiveness. Located in Eastern Europe, Kyiv offers skilled developers at competitive rates.`,
    uk: 'Nearshore development - поєднує високоякісний досвід із економічною ефективністю. Ми пропонуємо кваліфікованих розробників за конкурентними цінами.',
  },
  'section-collaboration-kyiv-recive': {
    en: `Working with us You will receive:`,
    uk: 'Працюючи з нами Ви отримаєте:',
  },
  'section-collaboration-kyiv-recive-1': {
    en: `Project work & support`,
    uk: 'Реалізація проектів та обслуговування.',
  },
  'section-collaboration-kyiv-recive-2': {
    en: `Single consultants or complete team can be hired`,
    uk: 'Можна найняти одного консультанта або повну команду',
  },
  'section-collaboration-kyiv-recive-3': {
    en: `Technical coordination of development teams in fluent English`,
    uk: 'Технічна координація команд розробників вільною англійською мовою',
  },
  'section-collaboration-kyiv-recive-4': {
    en: `Regular sprints, backlog, progress meetings`,
    uk: 'Регулярні спринти, керування завданнями, зустрічі для оцінки прогресу',
  },
  'section-collaboration-kyiv-recive-5': {
    en: `Fixed scope / price and Time & Material possible`,
    uk: 'Можливість зафіксувати обсяг / ціну, час та матеріали',
  },
  'section-collaboration-estonia': {
    en: `Development Services in Europe / Estonia`,
    uk: 'Development Services в Європі / Естонія.',
  },
  'section-collaboration-estonia-discription-1': {
    en: `With a strong focus on innovation, we offer a wide range of development services that drive businesses forward.`,
    uk: 'Приділяючи значну увагу інноваціям, ми пропонуємо широкий спектр послуг з розробки, які рухають бізнес вперед.',
  },
  'section-collaboration-estonia-discription-2': {
    en: ` Our team of skilled professionals is dedicated to delivering excellent solutions tailored to your unique needs. Join us in shaping the digital future of Europe.`,
    uk: 'Наша команда кваліфікованих професіоналів прагне надавати чудові рішення, адаптовані до ваших унікальних потреб. Приєднуйтесь до нас у формуванні цифрового майбутнього Європи.',
  },
  'section-collaboration-estonia-recive': {
    en: `Choosing this You will get:`,
    uk: 'Вибравши це, Ви отримаєте:',
  },
  'section-collaboration-estonia-recive-1': {
    en: `Remote development services`,
    uk: 'Послуги віддаленої розробки',
  },
  'section-collaboration-estonia-recive-2': {
    en: `Onsite work & joining of workshops possible`,
    uk: 'Робота на місці та участь у робочих зустрічах.',
  },
  'section-collaboration-estonia-recive-3': {
    en: `Regular sprints, product backlog & progress meetings`,
    uk: 'Регулярні спринти, керування завданнями, зустрічі для оцінки прогресу',
  },
  'section-collaboration-estonia-recive-4': {
    en: `Fixed scope/ price and Time & Material possible`,
    uk: 'Можливість зафіксувати обсяг / ціну, час та матеріали',
  },
  'contacts-title': {
    en: `OUR CONTACTS`,
    uk: 'КОНТАКТИ',
  },
  'contacts-director__post': {
    en: `International Business Development`,
    uk: 'Розвиток міжнародного бізнесу',
  },
  'director-name': {
    en: `Yuriy Kutsenko`,
    uk: 'Юрій Куценко',
  },
  'director-post-lead ': {
    en: `Operations & Business Lead`,
    uk: 'Керівник з розвитку бізнесу',
  },
  'footer-overview': {
    en: `Overview`,
    uk: 'Огляд',
  },
  'footer-who-we-are': {
    en: `Who We Are`,
    uk: 'Про нас',
  },
  'footer-benefits': {
    en: `Benefits`,
    uk: 'Переваги',
  },
  'footer-projects': {
    en: `Successful Project`,
    uk: 'Проекти',
  },
  'footer-contact-us': {
    en: `Contact us`,
    uk: "Зв'язатися з нами",
  },
  'footer-services': {
    en: `Services`,
    uk: 'Послуги',
  },
  'footer-services-technologies': {
    en: `Services & technologies`,
    uk: 'Послуги та технології',
  },
  'footer-collaboration': {
    en: `Collaboration`,
    uk: 'Співпраця',
  },
  'footer-contacts': {
    en: `Our Contacts`,
    uk: 'Контакти',
  },
  'ml-ai-services__title--main': {
    en: `ML & AI Services That Drive Success`,
    uk: 'Сервіси ML і AI, які сприяють успіху',
  },
  'ml-ai-services__title-second': {
    en: `Welcome to our Machine Learning, Artificial Intelligence & Data Science
        services page, where innovation meets intelligence.`,
    uk: 'Ласкаво просимо на сторінку послуг машинного навчання, штучного інтелекту та науки про дані, де інновації зустрічаються з інтелектом.',
  },
  'ml-ai-services__discription': {
    en: `Unlock the potential of AI-driven solutions with our expert team. From
        predictive analytics to natural language processing, we offer
        cutting-edge AI services tailored to your needs.`,
    uk: 'Розкрийте потенціал рішень на основі штучного інтелекту з нашою командою експертів. Від прогнозної аналітики до обробки природної мови. Ми пропонуємо передові послуги ШІ, адаптовані до ваших потреб.',
  },
  'ml-ai-title': {
    en: `Models & Algorithms`,
    uk: 'Моделі та алгоритми',
  },
  'ml-ai-text-title-mob': {
    en: `In our work we use such Models & Algorithms as:`,
    uk: 'У своїй роботі ми використовуємо такі моделі та алгоритми, як:',
  },
  'ml-ai-text-title-desk': {
    en: `In our work we use such Models & Algorithms as:`,
    uk: 'У своїй роботі ми використовуємо такі моделі та алгоритми, як:',
  },
  'ml-ai-models-1': {
    en: `ML & Data Analysis Tools`,
    uk: 'Інструменти машинного навчання та аналізу даних.',
  },
  'ml-ai-models-2': {
    en: `Machine learning prediction`,
    uk: 'Моделі машинного навчання для прогнозування.',
  },
  'ml-ai-models-3': {
    en: `Optimization of ML models using different algorithms such as BERT,
        GPT & LLM`,
    uk: 'Оптимізація моделей ML за допомогою алгоритмів, таких як BERT, GPT і LLM',
  },
  'ml-ai-models-4': {
    en: `Deep learning & Neuronal networks`,
    uk: 'Глибоке навчання та нейронні мережі.',
  },
  'ml-ai-tools-title-desk': {
    en: `ML & Data Analysis Tools like:`,
    uk: 'Інструменти машинного навчання та аналізу даних, такі як:',
  },
  'ml-ai-models-1-mob-1': {
    en: `ML & Data Analysis Tools`,
    uk: 'Інструменти машинного навчання та аналізу даних.',
  },
  'ml-ai-models-2-mob': {
    en: `Machine learning prediction`,
    uk: 'Моделі машинного навчання для прогнозування.',
  },
  'ml-ai-models-3-mob': {
    en: `Optimization of ML models using different algorithms such as BERT,
        GPT & LLM`,
    uk: 'Оптимізація моделей ML за допомогою алгоритмів, таких як BERT, GPT і LLM',
  },
  'ml-ai-models-4-mob': {
    en: `Deep learning & Neuronal networks`,
    uk: 'Глибоке навчання та нейронні мережі.',
  },
  'ml-ai-tools-title-mob': {
    en: `ML & Data Analysis Tools like:`,
    uk: 'Інструменти машинного навчання та аналізу даних, такі як:',
  },
  'ds-da-title': {
    en: `Data Science & Data Analytics`,
    uk: 'Data Science & Data Analytics',
  },
  'data-scince__discription-1': {
    en: `Cultivate insights and drive data-driven success with our Data Science & Data Analytics services.`,
    uk: 'Розвивайте інсайти та досягайте успіху спираючись на дані, - завдяки нашим послугам у галузі науки про дані та аналітики.',
  },
  'data-scince__discription-2': {
    en: ` Our expert team offers individual solutions, from predictive modeling to advanced analytics, empowering your business through data.`,
    uk: 'Наша команда експертів пропонує індивідуальні рішення, - від прогнозного моделювання до високорівневого аналізу, що надає вашому бізнесу розвиток завдяки данним.',
  },

  'data-scince__we-use': {
    en: `In Data Science / Data Analytics we use:`,
    uk: 'Ми використовуємо:',
  },

  'data-scince__we-use-1': {
    en: `Regression, Stochastic descent, Gradient boosting`,
    uk: 'Регресія, Стохастичний спуск, посилення Градієнта',
  },

  'data-scince__we-use-2': {
    en: ` Similarity, Classification, Clustering, PCA`,
    uk: 'Подібність, Класифікація, Кластеризація, PCA',
  },

  'data-scince__we-use-3': {
    en: `Embedding, Bayesian statistics / inference`,
    uk: 'Вбудовування, Байєсовська статистика / виведення',
  },
  'data-scince__we-use-4': {
    en: `Churn Prediction, Random Forests`,
    uk: 'Churn Prediction, Random Forests',
  },
  'data-scince__we-use-also': {
    en: `And also:`,
    uk: 'А також:',
  },
  'data-scince__we-use-also-1': {
    en: `Automated testing tools / Quality assurance`,
    uk: 'Засоби автоматизованого тестування та забезпечення якості',
  },
  'data-scince__we-use-also-2': {
    en: `Classic IT Software testing methods & tool`,
    uk: 'Класичні методи та інструменти для тестування програмного забезпечення.',
  },
  'data-scince__we-use-also-3': {
    en: `ML testing, Model Unit test, Regression test, Integration test`,
    uk: 'ML-тестування, Юніт тести, Регресійне тестування, Інтеграційне тестування.',
  },
  'serv-hero__title': {
    en: `Safeguarding Your Systems: SOC & IT Audits`,
    uk: 'Захист систем: оцінка безпеки та аудит ІТ-інфраструктури.',
  },
  'serv-hero__text': {
    en: `Explore our SOC services and IT audit offerings. Our team is here to enhance your security and compliance. Trust us for expert solutions.`,
    uk: 'Ознайомтеся з нашими послугами SOC та пропозиціями ІТ-аудиту. Наша команда тут, щоб підвищити вашу безпеку та дотримання вимог. Довіряйте нам експертні рішення.',
  },
  audits__title: {
    en: `SOC services & IT Audits`,
    uk: 'Послуги SOC та ІТ-аудит',
  },
  'audits__text-title': {
    en: `Trust us for data protection and IT security`,
    uk: 'Довіртеся нам щодо захисту даних та ІТ-безпеки',
  },
  'audits__text-span-1': {
    en: `Explore our SOC services and IT audit solutions, dedicated to fortifying your digital defenses and ensuring regulatory compliance.`,
    uk: 'Ознайомтеся з нашими послугами SOC та рішеннями для ІТ-аудиту, спрямованими на зміцнення цифрового захисту та забезпечення відповідності нормативним вимогам.',
  },
  'audits__text-span-2': {
    en: `Our offerings include thorough security assessments, vulnerability identification, and actionable insights to enhance your cybersecurity.`,
    uk: 'Наші пропозиції включають ретельні оцінки безпеки, виявлення вразливостей і корисні відомості для підвищення вашої кібербезпеки.',
  },
  'audits__list-title': {
    en: `We suggest the following:`,
    uk: 'Ми пропонуємо наступне:',
  },
  'audits__item-1': {
    en: `Information security system consulting`,
    uk: 'Консультації щодо систем інформаційної безпеки',
  },
  'audits__item-2': {
    en: `Expertise in ISO 27001:2005, Quality Control, Assessment, Testing`,
    uk: 'Експертиза в ISO 27001:2005, Контроль якості, Оцінка, Тестування',
  },
  'audits__item-3': {
    en: `Support activities for Security Operation Center (SOC)`,
    uk: 'Підтримка операційного центру безпеки (SOC)',
  },
  'audits__item-4': {
    en: `Deploy and configure ISM / SOC components: SIEM, FIM, DLP, MISP`,
    uk: 'Розгортання та налаштування компонентів ISM/SOC: SIEM, FIM, DLP, MISP',
  },
  'audits__item-5': {
    en: `Data exchange between SOC and other systems: API Services (Azure, IBM), Databases, BI Systems (PowerBI), Network Equipment`,
    uk: 'Обмін даними між SOC та іншими системами: API Services (Azure, IBM), Databases, BI Systems (PowerBI), Network Equipment',
  },
  'serv-tech-title': {
    en: `Services & technologies`,
    uk: 'Послуги та технології',
  },
  'serv-and-tech__list-header': {
    en: `In our work we use such Services & Technologies as:`,
    uk: 'У своїй роботі ми використовуємо: ',
  },
  'serv-and-tech__item-1': {
    en: `Projects in Artificial intelligence / Machine learning technologies`,
    uk: 'Проекти зі штучного інтелекту / Технології машинного навчання',
  },
  'serv-and-tech__item-2': {
    en: `Business & requirements analysis`,
    uk: 'Аналіз бізнесу та вимог',
  },
  'serv-and-tech__item-3': {
    en: `Data Science/ Data Analytics`,
    uk: 'Data Science/ Data Analytics',
  },
  'serv-and-tech__item-4': {
    en: `Cloud development & migration`,
    uk: 'Хмарна розробка та міграція в хмарне середовище',
  },
  'serv-and-tech__item-5': {
    en: `DevOps and solution architecture`,
    uk: 'DevOps і архітектура рішення',
  },
  'serv-and-tech__item-6': {
    en: `Embedded Software Engineering`,
    uk: 'Розробка вбудованого програмного забезпечення',
  },
  'serv-and-tech__item-7': {
    en: `Frontend / UI/UX, backend & database development`,
    uk: 'Frontend/UI/UX, backend та розробка баз даних',
  },
  'serv-and-tech__item-8': {
    en: `Expertise in newest concepts such as LLM / GPT`,
    uk: 'Експертиза в новітніх концепціях, таких як LLM / GPT',
  },
  'serv-and-tech__item-9': {
    en: `SOC services / IT Audits`,
    uk: 'SOC послуги / ІТ аудит',
  },
  'programming__title-tools': {
    en: `Tools & programming languages`,
    uk: 'Інструменти та мови програмування',
  },
  'programming__title-frameworks': {
    en: `Programming languages / frameworks`,
    uk: 'Мови програмування та фреймворки',
  },
  'tools__sub-title': {
    en: `Standards / tools:`,
    uk: 'Стандарти та інструменти:',
  },
  'tools-platforms': {
    en: `Platforms / architectures:`,
    uk: 'Платформи та архітектури:',
  },
  'tools-api': {
    en: `API  ́s / Protocols:`,
    uk: 'API та Протоколи:',
  },
  'tools-databases': {
    en: `Databases`,
    uk: 'Бази даних',
  },
  'seo-text-1-title': {
    en: `MBIT Consultants is a leading IT company specializing in providing high-quality software development services`,
    uk: 'MBIT Consultants - це передова IT-компанія, яка спеціалізується на наданні високоякісних послуг з розробки програмного забезпечення',
  },
  'seo-text-1-discription-1': {
    en: `MBIT Consultants is your reliable and strategic partner who is ready to take on the task of software development.`,
    uk: `MBIT Consultants - ваш надійний та стратегічний партнер, який готовий взяти на себе завдання розробки програмного забезпечення`,
  },
  'seo-text-1-discription-2': {
    en: `We understand your needs and are ready to provide an individual approach to solving the tasks of your business.`,
    uk: `Ми розуміємо ваші потреби та готові надати індивідуальний підхід до вирішення завдань вашого бізнесу`,
  },
  'seo-text-2-discription-1': {
    en: `Our team of experts at MBIT is a cohesive team of professionals with deep experience in the field of software development. We specialize in a wide range of services, but our core expertise is in data mining, machine learning and artificial intelligence.`,
    uk: `Наша команда експертів в MBIT - це злагоджений колектив професіоналів з глибоким досвідом у сфері розробки програмного забезпечення. Ми спеціалізуємося на широкому спектрі послуг, проте наша основна експертиза зосереджена на інтелектуальному аналізі даних, машинному навчанні та штучному інтелекті.`,
  },
  'seo-text-2-discription-2': {
    en: `Our advances in machine learning, artificial intelligence and data science enable us to use the latest technologies, such as GPT / LLM-based methods and algorithms, to achieve innovative results and provide advanced solutions for our clients. We are always at the forefront of the industry as we improve our skills and use the most advanced tools.`,
    uk: ` Наші досягнення в галузі машинного навчання, штучного інтелекту та науки про дані дозволяють нам використовувати новітні технології, такі як GPT / LLM-based методи та алгоритми, для досягнення інноваційних результатів та надання передових рішень для наших клієнтів. Ми завжди на передовій галузі, оскільки вдосконалюємо наші навички та використовуємо найсучасніші інструменти.`,
  },
  'seo-text-2-discription-3': {
    en: `Each member of our team is focused on providing the most effective AI solutions for your project. We carefully study all aspects of the task in order to find the optimal machine learning strategies to achieve the best results. Our goal is to ensure your success by combining our knowledge and experience to create high-quality software solutions that meet the highest standards and customer needs.`,
    uk: `Кожен учасник нашої команди зосереджений на забезпеченні найефективніших AI рішень для вашого проекту. Ми ретельно вивчаємо всі аспекти завдання з метою знаходження оптимальних стратегій у сфері машинного навчання, щоб досягти найкращих результатів. Наша мета - забезпечити ваш успіх, об'єднуючи наші знання та досвід для створення високоякісних програмних рішень, які відповідають найвищим стандартам та потребам клієнта.`,
  },
  'seo-text-3-title': {
    en: `MBIT company is:`,
    uk: `Компанія MBIT Consultants це:`,
  },
  'seo-text-3-item-1': {
    en: `Competitive prices for the best quality`,
    uk: `Конкурентоспроможні ціни без ушкодження якості`,
  },
  'seo-text-3-item-2': {
    en: `Understanding the requirements and budget of the business`,
    uk: `Розуміння вимог та бюджету бізнесу`,
  },
  'seo-text-3-item-3': {
    en: `Providing the most effective solutions for our clients`,
    uk: `Надання  найефективніших  рішеннь для наших клієнтів`,
  },
  'seo-text-3-discription-1': {
    en: `Communication is the key to a successful project. We strive to provide you with full control over every step of the work, ensuring transparency and confidence in the realization of your vision in the final product.`,
    uk: `Комунікація - запорука успішного проекту. Ми прагнемо забезпечити вам повний контроль над кожним кроком роботи, забезпечуючи прозорість та впевненість у втіленні вашого бачення у кінцевому продукті.`,
  },
  'seo-text-3-discription-2': {
    en: `At MBIT Consultants, your success is our top priority. We take the time to understand your business, your goals and challenges, providing solutions that meet your unique needs.`,
    uk: `У MBIT Consultants ваш успіх - наш головний пріоритет. Ми приділяємо час, щоб зрозуміти ваш бізнес, ваші цілі та виклики, надаючи рішення, які відповідають вашим унікальним потребам.`,
  },
  'hero-ml-ai-title': {
    en: `ML and AI services`,
    uk: `ML and AI послуги`,
  },
  'hero-ml-ai-discription-1': {
    en: `Businesses are increasingly turning to Artificial Intelligence (AI) and Machine Learning (ML) to gain a competitive edge. `,
    uk: `Сучасні підприємства все частіше звертаються до Штучного Інтелекту (ШІ) та Машинного Навчання (МН), щоб отримати конкурентну перевагу. `,
  },
  'hero-ml-ai-discription-2': {
    en: `MBIT Consultants is among the frontrunners in providing state-of-the-art ML and AI services.`,
    uk: `MBIT Consultants є одним із лідерів у наданні найсучасніших послуг ML та AI.`,
  },
  'hero-services-title': {
    en: `Services & Technologies`,
    uk: `Послуги та технології`,
  },
  'nav-contact-us-hero-services': {
    en: `Contact Us`,
    uk: `Зв'яжіться з нами`,
  },
  'nav-contact-us-hero-ml-ai': {
    en: `Contact Us`,
    uk: `Зв'яжіться з нами`,
  },
  'ml-ai-services-seotext-1': {
    en: `At MBIT Consultants, our approach to AI transcends mere automation – we're architects of intelligent systems designed to mimic human cognitive functions. Our AI solutions are crafted to enhance decision-making, automate complex tasks, and provide businesses with a competitive edge in an ever-evolving landscape.`,
    uk: `У MBIT Consultants наше бачення ШІ виходить за межі звичайної автоматизації – ми є архітекторами інтелектуальних систем, створених для імітації когнітивних функцій людини. Наші рішення з ШІ призначені для покращення прийняття рішень, автоматизації складних завдань та забезпечення підприємств конкурентною перевагою в постійно змінному ландшафті.`,
  },
  'ml-ai-services-title-1': {
    en: `Using machine learning (ML) models for forecasting has many business benefits:`,
    uk: `Використання моделей машинного навчання (ML) для прогнозування має
        безліч переваг для бізнесу:`,
  },
  'ml-models-benefits__title-1': {
    en: `Optimization of resources through forecasting:`,
    uk: `Оптимізація ресурсів через прогнозування:`,
  },
  'ml-models-benefits__discription-1': {
    en: `Demand analysis helps avoid overstocking.`,
    uk: ` Аналіз попиту допомагає уникнути надмірного запасу товарів.`,
  },
  'ml-models-benefits__title-2': {
    en: `Personalization of products and services:`,
    uk: ` Персоналізація продуктів і послуг:`,
  },
  'ml-models-benefits__discription-2': {
    en: `Forecasting models facilitate the development of individual solutions for customers.`,
    uk: `Моделі прогнозування сприяють розробці індивідуальних рішень для клієнтів.`,
  },
  'ml-models-benefits__title-3': {
    en: `Accurate forecasts for strategic decisions:`,
    uk: `Точні прогнози для стратегічних рішень:`,
  },
  'ml-models-benefits__discription-3': {
    en: `Machine learning makes it possible to more accurately predict what is important for managing production, inventory and finances.`,
    uk: `Машинне навчання дозволяє точніше прогнозувати, що важливо для управління виробництвом, запасами та фінансами.`,
  },
  'ml-models-benefits__title-4': {
    en: `Improving strategic planning based on forecasts:`,
    uk: `Покращення стратегічного планування на основі прогнозів:`,
  },
  'ml-models-benefits__discription-4': {
    en: `Forecasts help in developing future strategies, taking into account market and consumer trends.`,
    uk: `Прогнози допомагають у розробці майбутніх стратегій, враховуючи тенденції ринку та споживачів.`,
  },
  'ml-models-benefits__title-5': {
    en: `Risk minimization and strategic flexibility:`,
    uk: `Мінімізація ризиків і стратегічна гнучкість:`,
  },
  'ml-models-benefits__discription-5': {
    en: `Forecasting helps identify risks and prepare for different scenarios.`,
    uk: `Прогнозування допомагає ідентифікувати ризики та готуватися до різних сценаріїв.`,
  },
  'ml-models-benefits__title-6': {
    en: `Effective marketing management:`,
    uk: `Ефективне управління маркетингом:`,
  },
  'ml-models-benefits__discription-6': {
    en: `Analysis of the effectiveness of campaigns and their effects on the market through marketing`,
    uk: `Аналіз ефективності кампаній та їхніх наслідків на ринку через маркетинг`,
  },
  'ml-models-benefits__title-1-mob': {
    en: `Optimization of resources through forecasting:`,
    uk: `Оптимізація ресурсів через прогнозування:`,
  },
  'ml-models-benefits__discription-1-mob': {
    en: `Demand analysis helps avoid overstocking.`,
    uk: ` Аналіз попиту допомагає уникнути надмірного запасу товарів.`,
  },
  'ml-models-benefits__title-2-mob': {
    en: `Personalization of products and services:`,
    uk: ` Персоналізація продуктів і послуг:`,
  },
  'ml-models-benefits__discription-2-mob': {
    en: `Forecasting models facilitate the development of individual solutions for customers.`,
    uk: `Моделі прогнозування сприяють розробці індивідуальних рішень для клієнтів.`,
  },
  'ml-models-benefits__title-3-mob': {
    en: `Accurate forecasts for strategic decisions:`,
    uk: `Точні прогнози для стратегічних рішень:`,
  },
  'ml-models-benefits__discription-3-mob': {
    en: `Machine learning makes it possible to more accurately predict what is important for managing production, inventory and finances.`,
    uk: `Машинне навчання дозволяє точніше прогнозувати, що важливо для управління виробництвом, запасами та фінансами.`,
  },
  'ml-models-benefits__title-4-mob': {
    en: `Improving strategic planning based on forecasts:`,
    uk: `Покращення стратегічного планування на основі прогнозів:`,
  },
  'ml-models-benefits__discription-4-mob': {
    en: `Forecasts help in developing future strategies, taking into account market and consumer trends.`,
    uk: `Прогнози допомагають у розробці майбутніх стратегій, враховуючи тенденції ринку та споживачів.`,
  },
  'ml-models-benefits__title-5-mob': {
    en: `Risk minimization and strategic flexibility:`,
    uk: `Мінімізація ризиків і стратегічна гнучкість:`,
  },
  'ml-models-benefits__discription-5-mob': {
    en: `Forecasting helps identify risks and prepare for different scenarios.`,
    uk: `Прогнозування допомагає ідентифікувати ризики та готуватися до різних сценаріїв.`,
  },
  'ml-models-benefits__title-6-mob': {
    en: `Effective marketing management:`,
    uk: `Ефективне управління маркетингом:`,
  },
  'ml-models-benefits__discription-6-mob': {
    en: `Analysis of the effectiveness of campaigns and their effects on the market through marketing`,
    uk: `Аналіз ефективності кампаній та їхніх наслідків на ринку через маркетинг`,
  },
  'ml-ai-seo-text-2-discription-1': {
    en: `At the heart of effective AI and ML lies robust data management. MBIT Consultants goes beyond the ordinary, employing advanced data analytics to ensure that every byte of information is leveraged to its full potential. This commitment to data excellence sets the stage for the creation of intelligent, adaptive systems.`,
    uk: `У серці ефективності ШІ та МН лежить міцне управління даними. MBIT
      Consultants виходить за межі звичайного, використовуючи високорівневий
      аналіз даних, щоб забезпечити повне використання кожного байта інформації.
      Це зобов'язання до відмінності в роботі з даними створює підґрунтя для
      створення інтелектуальних, адаптивних систем.`,
  },
  'ml-ai-seo-text-2-discription-2': {
    en: `Machine Learning (ML) stands as the backbone of MBIT Consultants' technological prowess. Our ML models are not static entities but living, evolving systems that learn and adapt. Through cutting-edge algorithms and advanced data analytics, we empower businesses to unlock the full potential of our data. Whether it's predicting market trends, optimizing operations, or personalizing user experiences, our AI developers are tailored to meet the unique needs of each client, ensuring scalability and sustained success.
`,
    uk: ` Машинне Навчання (МН) є основою технологічної майстерності MBIT
      Consultants. Наші моделі МН – це не статичні сутності, а живі, еволюційні
      системи, які вчаться та адаптуються. За допомогою передових алгоритмів та
      високорівневого аналізу даних ми дозволяємо підприємствам розкрити повний
      потенціал їх даних. Чи це передбачення ринкових тенденцій, оптимізація
      операцій чи персоналізація користувацьких вражень – наші рішення МН
      адаптовані до унікальних потреб кожного клієнта, забезпечуючи
      масштабованість та стійкий успіх.`,
  },
  'ml-ai-seo-text-2-services-title': {
    en: `Deep learning and neural networks can bring significant benefits to business in various industries.`,
    uk: `Глибоке навчання та нейронні мережі можуть принести значні переваги для бізнесу в різних галузях.`,
  },
  'ml-models-deep-learning__title-1': {
    en: `Automation of business processes:`,
    uk: `Автоматизація бізнес-процесів:`,
  },
  'ml-models-deep-learning__discription-1': {
    en: `Neural networks automate routine tasks, freeing up resources
             for more complex tasks.`,
    uk: ` Нейронні мережі автоматизують рутинні завдання, звільняючи ресурси
            для складніших задач.`,
  },
  'ml-models-deep-learning__title-2': {
    en: `Improving products and services:`,
    uk: ` Покращення продуктів і послуг:`,
  },
  'ml-models-deep-learning__discription-2': {
    en: `Deep neural networks detect features in data, improving quality
             products and recommendations.`,
    uk: ` Глибокі нейронні мережі виявляють ознаки в даних, покращуючи якість
            продуктів і рекомендацій.`,
  },
  'ml-models-deep-learning__title-3': {
    en: `Customer experience:`,
    uk: `Клієнтський досвід:`,
  },
  'ml-models-deep-learning__discription-3': {
    en: `Deep learning helps create intelligent systems
             recommendations, increasing customer satisfaction.`,
    uk: `Глибоке навчання допомагає створювати інтелектуальні системи
            рекомендацій, підвищуючи задоволення клієнтів.`,
  },
  'ml-models-deep-learning__title-4': {
    en: `Competitive advantage:`,
    uk: `Конкурентна перевага:`,
  },
  'ml-models-deep-learning__discription-4': {
    en: `Companies with deep learning grow faster by getting
             competitive advantage in the market.`,
    uk: ` Компанії з глибоким навчанням розвиваються швидше, отримуючи
            конкурентну перевагу на ринку.`,
  },
  'ml-models-deep-learning__title-5': {
    en: `Effective resource management:`,
    uk: `Ефективне управління ресурсами:`,
  },
  'ml-models-deep-learning__discription-5': {
    en: `Using deep learning manages inventory and optimizes
             processes to reduce costs.`,
    uk: `Використання глибокого навчання управляє запасами та оптимізує
            процеси для зменшення витрат.`,
  },
  'ml-models-deep-learning__title-6': {
    en: `Decision-making processes:`,
    uk: `Процеси прийняття рішень:`,
  },
  'ml-models-deep-learning__discription-6': {
    en: `Deep learning analyzes big data for informed and strategic decisions.`,
    uk: `Глибоке навчання аналізує великі дані для обґрунтованих та стратегічних рішень.`,
  },
  'ml-models-deep-learning__title-1-mob': {
    en: `Automation of business processes:`,
    uk: `Автоматизація бізнес-процесів:`,
  },
  'ml-models-deep-learning__discription-1-mob': {
    en: `Neural networks automate routine tasks, freeing up resources for more complex tasks.`,
    uk: ` Нейронні мережі автоматизують рутинні завдання, звільняючи ресурси
            для складніших задач.`,
  },
  'ml-models-deep-learning__title-2-mob': {
    en: `Improving products and services:`,
    uk: ` Покращення продуктів і послуг:`,
  },
  'ml-models-deep-learning__discription-2-mob': {
    en: `Deep neural networks detect features in data, improving quality
             products and recommendations.`,
    uk: ` Глибокі нейронні мережі виявляють ознаки в даних, покращуючи якість
            продуктів і рекомендацій.`,
  },
  'ml-models-deep-learning__title-3-mob': {
    en: `Customer experience:`,
    uk: `Клієнтський досвід:`,
  },
  'ml-models-deep-learning__discription-3-mob': {
    en: `Deep learning helps create intelligent systems
             recommendations, increasing customer satisfaction.`,
    uk: `Глибоке навчання допомагає створювати інтелектуальні системи
            рекомендацій, підвищуючи задоволення клієнтів.`,
  },
  'ml-models-deep-learning__title-4-mob': {
    en: `Competitive advantage:`,
    uk: `Конкурентна перевага:`,
  },
  'ml-models-deep-learning__discription-4-mob': {
    en: `Companies with deep learning grow faster by getting
             competitive advantage in the market.`,
    uk: ` Компанії з глибоким навчанням розвиваються швидше, отримуючи
            конкурентну перевагу на ринку.`,
  },
  'ml-models-deep-learning__title-5-mob': {
    en: `Effective resource management:`,
    uk: `Ефективне управління ресурсами:`,
  },
  'ml-models-deep-learning__discription-5-mob': {
    en: `Using deep learning manages inventory and optimizes
             processes to reduce costs.`,
    uk: `Використання глибокого навчання управляє запасами та оптимізує
            процеси для зменшення витрат.`,
  },
  'ml-models-deep-learning__title-6-mob': {
    en: `Decision-making processes:`,
    uk: `Процеси прийняття рішень:`,
  },
  'ml-models-deep-learning__discription-6-mob': {
    en: `Deep learning analyzes big data for informed and strategic decisions.`,
    uk: `Глибоке навчання аналізує великі дані для обґрунтованих та стратегічних рішень.`,
  },

  'ml-ai-seo-text-3-discription-1': {
    en: `Adopting new technologies shouldn't mean an upheaval of existing systems. MBIT Consultants recognizes the importance of seamless integration. The team works closely with clients to integrate AI and ML solutions into current workflows, minimizing disruption and maximizing efficiency.`,
    uk: `Впровадження нових технологій не повинно означати перевертання існуючих
      систем. MBIT Consultants розуміє важливість безперешкодної інтеграції.
      Команда тісно співпрацює з клієнтами, щоб впровадити рішення ШІ та МН в їх
      поточні робочі процеси, мінімізуючи ризики і максимізуючи ефективність.`,
  },
  'ml-ai-seo-text-3-discription-2': {
    en: `The tech landscape is ever-evolving, and so are the challenges businesses face. MBIT Consultants stays ahead of the curve by fostering a culture of continuous innovation. From staying updated with the latest algorithms to exploring emerging technologies, the team is dedicated to keeping your business at the forefront of technological advancements.`,
    uk: `Технологічний ландшафт постійно розвивається, так само, як і виклики, які
      ставляться перед бізнесом. MBIT Consultants виходить за межі, сприяючи
      культурі постійної інновації. Від оновлення останніх алгоритмів до
      вивчення новітніх технологій, команда присвячена тому, щоб ваш бізнес
      залишався на передовій технологічних досягнень.`,
  },
  'ml-ai-seo-text-3-services-title': {
    en: `Optimizing machine learning (ML) models using advanced
         algorithms such as BERT, GPT (Generative Pre-trained Transformer) and
         LLM (Large Language Model), will bring a number of important advantages for business:`,
    uk: ` Оптимізація моделей машинного навчання (ML) за допомогою передових
        алгоритмів, таких як BERT, GPT (Generative Pre-trained Transformer) і
        LLM (Large Language Model), принесе ряд важливих переваг для бізнесу:`,
  },
  'ml-models-optimization__title-1': {
    en: `Improving the accuracy of ML models:`,
    uk: ` Підвищення точності моделей ML:`,
  },
  'ml-models-optimization__discription-1': {
    en: ` The use of advanced algorithms such as BERT and GPT increases accuracy and efficiency in natural language processing tasks such as such as machine translation and context analysis.`,
    uk: `  Використання передових алгоритмів, таких як BERT і GPT, підвищує точність та ефективність у завданнях обробки природної мови, таких як машинний переклад та аналіз контексту.`,
  },
  'ml-models-optimization__title-2': {
    en: `Optimization of natural language processing (NLP):`,
    uk: `Оптимізація обробки природної мови (NLP):`,
  },
  'ml-models-optimization__discription-2': {
    en: `BERT and GPT algorithms improve natural language processing systems, including automated question answers, sentiment analysis and information extraction.`,
    uk: `  Алгоритми BERT і GPT покращують системи обробки природної мови, включаючи автоматизовані відповіді на запитання, аналіз настроїв та екстракцію інформації.`,
  },
  'ml-models-optimization__title-3': {
    en: `Improved response to the user:`,
    uk: `Покращення реакції на користувача:`,
  },
  'ml-models-optimization__discription-3': {
    en: `Using these algorithms helps in improving the systems recommendations and personalization of content, providing a better experience users with business products or services.`,
    uk: `Використання цих алгоритмів допомагає у поліпшенні систем рекомендацій та персоналізації контенту, забезпечуючи кращий досвід користувачів з продуктами чи послугами бізнесу.`,
  },
  'ml-models-optimization__title-4': {
    en: `Reducing model development costs:`,
    uk: ` Зменшення витрат на розробку моделей:`,
  },
  'ml-models-optimization__discription-4': {
    en: `Using advanced algorithms in pre-trained models allows to reduce the time and costs for the development of new models, using ready-made solutions and trained parameters of these algorithms.`,
    uk: ` Використання передових алгоритмів у попередньо навчених моделях дозволяє знизити час та витрати на розробку нових моделей, використовуючи готові рішення та навчені параметри цих алгоритмів.`,
  },
  'ml-models-optimization__title-5': {
    en: `Improving understanding of context:`,
    uk: ` Покращення розуміння контексту:`,
  },
  'ml-models-optimization__discription-5': {
    en: `The BERT and GPT algorithms learn to understand the context in text that useful for analyzing large volumes of documents and interacting with by users in web services.`,
    uk: `Алгоритми BERT і GPT навчаються розуміти контекст у тексті, що корисно для аналізу великих обсягів документів та взаємодії з користувачами у веб-сервісах.`,
  },
  'ml-models-optimization__title-1-mob': {
    en: `Improving the accuracy of ML models:`,
    uk: ` Підвищення точності моделей ML:`,
  },
  'ml-models-optimization__discription-1-mob': {
    en: ` The use of advanced algorithms such as BERT and GPT increases accuracy and efficiency in natural language processing tasks such as such as machine translation and context analysis.`,
    uk: `  Використання передових алгоритмів, таких як BERT і GPT, підвищує точність та ефективність у завданнях обробки природної мови, таких як машинний переклад та аналіз контексту.`,
  },
  'ml-models-optimization__title-2-mob': {
    en: `Optimization of natural language processing (NLP):`,
    uk: `Оптимізація обробки природної мови (NLP):`,
  },
  'ml-models-optimization__discription-2-mob': {
    en: `BERT and GPT algorithms improve natural language processing systems, including automated question answers, sentiment analysis and information extraction.`,
    uk: `  Алгоритми BERT і GPT покращують системи обробки природної мови, включаючи автоматизовані відповіді на запитання, аналіз настроїв та екстракцію інформації.`,
  },
  'ml-models-optimization__title-3-mob': {
    en: `Improved response to the user:`,
    uk: `Покращення реакції на користувача:`,
  },
  'ml-models-optimization__discription-3-mob': {
    en: `Using these algorithms helps in improving the systems recommendations and personalization of content, providing a better experience users with business products or services.`,
    uk: `Використання цих алгоритмів допомагає у поліпшенні систем рекомендацій та персоналізації контенту, забезпечуючи кращий досвід користувачів з продуктами чи послугами бізнесу.`,
  },
  'ml-models-optimization__title-4-mob': {
    en: `Reducing model development costs:`,
    uk: ` Зменшення витрат на розробку моделей:`,
  },
  'ml-models-optimization__discription-4-mob': {
    en: `Using advanced algorithms in pre-trained models allows to reduce the time and costs for the development of new models, using ready-made solutions and trained parameters of these algorithms.`,
    uk: ` Використання передових алгоритмів у попередньо навчених моделях дозволяє знизити час та витрати на розробку нових моделей, використовуючи готові рішення та навчені параметри цих алгоритмів.`,
  },
  'ml-models-optimization__title-5-mob': {
    en: `Improving understanding of context:`,
    uk: ` Покращення розуміння контексту:`,
  },
  'ml-models-optimization__discription-5-mob': {
    en: `The BERT and GPT algorithms learn to understand the context in text that useful for analyzing large volumes of documents and interacting with by users in web services.`,
    uk: `Алгоритми BERT і GPT навчаються розуміти контекст у тексті, що корисно для аналізу великих обсягів документів та взаємодії з користувачами у веб-сервісах.`,
  },
  'hero-rpa-title': {
    en: `Robotic Process Automation Services & Solutions`,
    uk: `Роботизовані послуги та рішення з автоматизації процесів`,
  },
  'rpa-discription-1': {
    en: `In the fast-paced world of business, staying ahead means embracing innovation, and one technological marvel that has been transforming industries is Robotic Process Automation (RPA). At MBIT Consultants, we're not just talking about efficiency; we're talking about a revolution in how businesses operate. `,
    uk: `У стрімкому світі бізнесу залишатися попереду означає впроваджувати
        інновації, і одним із технологічних див, яке трансформувало галузі, є
        робототизована автоматизація процесів (RPA). У MBIT Consultants ми
        говоримо не лише про ефективність; ми говоримо про революцію в тому, як
        працюють підприємства.`,
  },
  'rpa-seo-text-discription-1': {
    en: `Robotic Process Automation services have emerged as the go-to solution for businesses looking to streamline their operations. Tasks that once required hours of manual effort can now be executed with precision by virtual bots, leaving your human workforce to focus on strategic initiatives that truly drive growth.`,
    uk: `Роботизовані послуги автоматизації процесів стали основним рішенням для підприємств, які прагнуть оптимізувати свою діяльність. Завдання, які колись вимагали годин ручних зусиль, тепер можуть виконуватися з точністю віртуальними ботами, залишаючи вашу робочу силу зосереджено на стратегічних ініціативах, які справді стимулюють зростання.`,
  },
  'rpa-list-1-title-1': {
    en: `Expertise in RPA`,
    uk: `Експертність в галузі RPA`,
  },
  'rpa-list-1-discription-1': {
    en: `Our team consists of experienced specialists with deep knowledge in the field of Robotic Process Automation.`,
    uk: `Наша команда складається з досвідчених фахівців з глибокими знаннями в області Robotic Process Automation.`,
  },
  'rpa-list-1-title-2': {
    en: `Individual solutions`,
    uk: `Індивідуальний підхід`,
  },
  'rpa-list-1-discription-2': {
    en: `We work on each project individually, providing development
             solutions that are optimized for your specific needs and goals
             business`,
    uk: `  Ми працюємо над кожним проектом індивідуально, забезпечуючи розробку
            рішень, які оптимізовані під конкретні потреби та цілі вашого
            бізнесу.`,
  },
  'rpa-list-1-title-3': {
    en: `Fast implementation`,
    uk: `Швидке впровадження`,
  },
  'rpa-list-1-discription-3': {
    en: `Our solutions ensure a fast and efficient implementation process,
             allowing you to quickly reap the benefits of automation.`,
    uk: `Наші рішення забезпечують швидкий та ефективний процес впровадження,
            що дозволяє вам швидко отримати переваги від автоматизації.`,
  },
  'rpa-list-1-title-4': {
    en: `Scalability and flexibility`,
    uk: `Масштабованість та гнучкість`,
  },
  'rpa-list-1-discription-4': {
    en: `Our works are easily scalable and can adapt to changes in
             your business and market requirements.`,
    uk: `Наші роботи легко масштабуються і можуть адаптуватися до змін у
            вашому бізнесі та вимог ринку.`,
  },
  'rpa-list-1-title-5': {
    en: `Reducing errors and increasing productivity`,
    uk: `Зниження помилок та підвищення продуктивності`,
  },
  'rpa-list-1-discription-5': {
    en: `RPA significantly reduces errors while increasing accuracy
             operations and freeing your staff from routine work for
             performance of more complex tasks.`,
    uk: ` RPA дозволяє значно зменшити кількість помилок, підвищуючи точність
            операцій та звільняючи ваш персонал від рутинної роботи для
            виконання більш складних завдань.`,
  },
  'rpa-list-1-title-6': {
    en: `Saving resources`,
    uk: `Економія ресурсів`,
  },
  'rpa-list-1-discription-6': {
    en: `Automation of processes allows efficient use of resources and
             reduces company costs.`,
    uk: ` Автоматизація процесів дозволяє ефективно використовувати ресурси та
            знижує витрати компанії.`,
  },
  'rpa-list-1-title-1-mob': {
    en: `Expertise in RPA`,
    uk: `Експертність в галузі RPA`,
  },
  'rpa-list-1-discription-1-mob': {
    en: `Our team consists of experienced specialists with deep knowledge in the field of Robotic Process Automation.`,
    uk: `Наша команда складається з досвідчених фахівців з глибокими знаннями в області Robotic Process Automation.`,
  },
  'rpa-list-1-title-2-mob': {
    en: `Individual solutions`,
    uk: `Індивідуальний підхід`,
  },
  'rpa-list-1-discription-2-mob': {
    en: `We work on each project individually, providing development
             solutions that are optimized for your specific needs and goals
             business`,
    uk: `  Ми працюємо над кожним проектом індивідуально, забезпечуючи розробку
            рішень, які оптимізовані під конкретні потреби та цілі вашого
            бізнесу.`,
  },
  'rpa-list-1-title-3-mob': {
    en: `Fast implementation`,
    uk: `Швидке впровадження`,
  },
  'rpa-list-1-discription-3-mob': {
    en: `Our solutions ensure a fast and efficient implementation process,
             allowing you to quickly reap the benefits of automation.`,
    uk: `Наші рішення забезпечують швидкий та ефективний процес впровадження,
            що дозволяє вам швидко отримати переваги від автоматизації.`,
  },
  'rpa-list-1-title-4-mob': {
    en: `Scalability and flexibility`,
    uk: `Масштабованість та гнучкість`,
  },
  'rpa-list-1-discription-4-mob': {
    en: `Our works are easily scalable and can adapt to changes in
             your business and market requirements.`,
    uk: `Наші роботи легко масштабуються і можуть адаптуватися до змін у
            вашому бізнесі та вимог ринку.`,
  },
  'rpa-list-1-title-5-mob': {
    en: `Reducing errors and increasing productivity`,
    uk: `Зниження помилок та підвищення продуктивності`,
  },
  'rpa-list-1-discription-5-mob': {
    en: `RPA significantly reduces errors while increasing accuracy
             operations and freeing your staff from routine work for
             performance of more complex tasks.`,
    uk: ` RPA дозволяє значно зменшити кількість помилок, підвищуючи точність
            операцій та звільняючи ваш персонал від рутинної роботи для
            виконання більш складних завдань.`,
  },
  'rpa-list-1-title-6-mob': {
    en: `Saving resources`,
    uk: `Економія ресурсів`,
  },
  'rpa-list-1-discription-6-mob': {
    en: `Automation of processes allows efficient use of resources and
             reduces company costs.`,
    uk: ` Автоматизація процесів дозволяє ефективно використовувати ресурси та
            знижує витрати компанії.`,
  },

  'rpa-section-title-1': {
    en: `Embracing Efficiency with RPA`,
    uk: `Підвищення ефективності з RPA`,
  },
  'rpa-seo-text-discription-2': {
    en: `We understand that time is money. With RPA services, repetitive and rule-based tasks are automated, freeing up valuable resources for more strategic and creative endeavors. This boosts efficiency and minimizes the risk of errors, ensuring that your business processes are executed flawlessly.`,
    uk: `Ми розуміємо, що час – гроші. Сервіси RPA автоматизують повторювані та
      засновані на правилах завдання, що звільняє цінні ресурси для більш
      стратегічних і творчих зусиль. Це підвищує ефективність і мінімізує ризик
      помилок, забезпечуючи бездоганне виконання ваших бізнес-процесів.`,
  },
  'rpa-seo-text-discription-3': {
    en: `Our company offer cutting-edge solutions tailored to meet the unique needs of your business. Our approach is to collaborate with you to understand your processes and implement RPA in a way that maximizes its potential.`,
    uk: `Наша компанія пропонує передові рішення, розроблені відповідно до
      унікальних потреб вашого бізнесу. Наш підхід полягає в тому, щоб
      співпрацюючи з вами зрозуміти ваші процеси та запровадити RPA таким чином,
      щоб максимізувати його потенціал.`,
  },
  'rpa-seo-text-discription-4': {
    en: `We recognize that each business is unique, and so are its processes. Our robotic process automation solutions are bespoke solutions designed to seamlessly integrate with your existing systems. This ensures a smooth transition to automation without disrupting your day-to-day operations.`,
    uk: `Ми розуміємо, що кожен бізнес унікальний, як і його процеси. Наші
      роботизовані рішення для автоматизації процесів — це індивідуальні
      рішення, розроблені для бездоганної інтеграції з вашими існуючими
      системами. Це забезпечує плавний перехід до автоматизації, не порушуючи
      повсякденну роботу.`,
  },
  'rpa-list-2-title-1': {
    en: `Optimizing processes based on data insights`,
    uk: `Оптимізація процесів на основі розуміння даних`,
  },
  'rpa-list-2-discription-1': {
    en: `ML, AI and Data Science contribute to the creation of predictive models and
             data analysis to identify patterns. When these technologies
             combined with RPA, they help to understand and optimize
             business processes based on collected data.`,
    uk: `ML, AI та Data Science сприяють створенню прогностичних моделей та
            аналізу даних для виявлення патернів. Коли ці технології
            об'єднуються з RPA, вони допомагають розуміти та оптимізувати
            бізнес-процеси на основі зібраних даних.`,
  },
  'rpa-list-2-title-2': {
    en: `Improving decision making based on analytics`,
    uk: `Покращення прийняття рішень на основі аналітики`,
  },
  'rpa-list-2-discription-2': {
    en: `Combining RPA with Data Science allows you to automate collection and analysis
             a large volume of data, which facilitates the adoption of more reasonable ones
             strategic decisions based on high-quality analytics.`,
    uk: ` Об'єднання RPA з Data Science дозволяє автоматизувати збір та аналіз
            великого обсягу даних, що полегшує прийняття більш обґрунтованих
            стратегічних рішень на основі високоякісної аналітики.`,
  },
  'rpa-list-2-title-3': {
    en: `Fast processing and use of data`,
    uk: `  Швидка обробка та використання даних`,
  },
  'rpa-list-2-discription-3': {
    en: `With RPA, data can be automatically collected, processed and
             analyze in real time. Integration with Data Science technologies
             allows you to quickly highlight useful information and receive valuable data
             for business`,
    uk: ` Завдяки RPA, дані можна автоматично збирати, обробляти та
            аналізувати у реальному часі. Інтеграція з технологіями Data Science
            дозволяє швидше виділяти корисну інформацію та отримувати цінні дані
            для бізнесу.`,
  },
  'rpa-list-2-title-4': {
    en: `Increasing the accuracy of data processing`,
    uk: `Збільшення точності обробки даних`,
  },
  'rpa-list-2-discription-4': {
    en: `RPA facilitates the automation of repetitive data processing tasks,
             reducing the risk of errors. A combination of ML and AI can help
             improve this process by providing more accurate processing and analysis
             data by training models based on the collected data.`,
    uk: `RPA сприяє автоматизації повторюваних завдань обробки даних,
            зменшуючи ризик помилок. Поєднання з ML та AI може допомогти
            вдосконалити цей процес, забезпечуючи точнішу обробку та аналіз
            даних за рахунок навчання моделей на підставі зібраних даних.`,
  },
  'rpa-list-2-title-5': {
    en: `Optimization of work flows and automation of interaction with
               systems:`,
    uk: ` Оптимізація робочих потоків та автоматизація взаємодії з
              системами:`,
  },
  'rpa-list-2-discription-5': {
    en: `RPA can integrate with data processing systems, simplifying processes
             transfer and exchange of information between different platforms, which accelerates
             workflows and increases productivity.`,
    uk: `RPA може інтегруватися з системами обробки даних, спрощуючи процеси
            передачі та обміну інформацією між різними платформами, що прискорює
            робочі потоки та підвищує продуктивність.`,
  },
  'rpa-list-2-title-6': {
    en: `Automation of routine tasks based on data analysis:`,
    uk: ` Автоматизація рутинних завдань на основі аналізу даних:`,
  },
  'rpa-list-2-discription-6': {
    en: `Integrating RPA with Data Science allows you to create intelligent
             robots that automate not only routine processes, but also perform them
             tasks based on data analysis and processing.`,
    uk: ` Інтеграція RPA з Data Science дозволяє створювати інтелектуальні
            роботи, які автоматизують не тільки рутинні процеси, але й виконують
            завдання на основі аналізу та обробки даних.`,
  },
  'rpa-list-2-title-1-mob': {
    en: `Optimizing processes based on data insights`,
    uk: `Оптимізація процесів на основі розуміння даних`,
  },
  'rpa-list-2-discription-1-mob': {
    en: `ML, AI and Data Science contribute to the creation of predictive models and
             data analysis to identify patterns. When these technologies
             combined with RPA, they help to understand and optimize
             business processes based on collected data.`,
    uk: `ML, AI та Data Science сприяють створенню прогностичних моделей та
            аналізу даних для виявлення патернів. Коли ці технології
            об'єднуються з RPA, вони допомагають розуміти та оптимізувати
            бізнес-процеси на основі зібраних даних.`,
  },
  'rpa-list-2-title-2-mob': {
    en: `Improving decision making based on analytics`,
    uk: `Покращення прийняття рішень на основі аналітики`,
  },
  'rpa-list-2-discription-2-mob': {
    en: `Combining RPA with Data Science allows you to automate collection and analysis
             a large volume of data, which facilitates the adoption of more reasonable ones
             strategic decisions based on high-quality analytics.`,
    uk: ` Об'єднання RPA з Data Science дозволяє автоматизувати збір та аналіз
            великого обсягу даних, що полегшує прийняття більш обґрунтованих
            стратегічних рішень на основі високоякісної аналітики.`,
  },
  'rpa-list-2-title-3-mob': {
    en: `Fast processing and use of data`,
    uk: `  Швидка обробка та використання даних`,
  },
  'rpa-list-2-discription-3-mob': {
    en: `With RPA, data can be automatically collected, processed and
             analyze in real time. Integration with Data Science technologies
             allows you to quickly highlight useful information and receive valuable data
             for business`,
    uk: ` Завдяки RPA, дані можна автоматично збирати, обробляти та
            аналізувати у реальному часі. Інтеграція з технологіями Data Science
            дозволяє швидше виділяти корисну інформацію та отримувати цінні дані
            для бізнесу.`,
  },
  'rpa-list-2-title-4-mob': {
    en: `Increasing the accuracy of data processing`,
    uk: `Збільшення точності обробки даних`,
  },
  'rpa-list-2-discription-4-mob': {
    en: `RPA facilitates the automation of repetitive data processing tasks,
             reducing the risk of errors. A combination of ML and AI can help
             improve this process by providing more accurate processing and analysis
             data by training models based on the collected data.`,
    uk: `RPA сприяє автоматизації повторюваних завдань обробки даних,
            зменшуючи ризик помилок. Поєднання з ML та AI може допомогти
            вдосконалити цей процес, забезпечуючи точнішу обробку та аналіз
            даних за рахунок навчання моделей на підставі зібраних даних.`,
  },
  'rpa-list-2-title-5-mob': {
    en: `Optimization of work flows and automation of interaction with
               systems:`,
    uk: ` Оптимізація робочих потоків та автоматизація взаємодії з
              системами:`,
  },
  'rpa-list-2-discription-5-mob': {
    en: `RPA can integrate with data processing systems, simplifying processes
             transfer and exchange of information between different platforms, which accelerates
             workflows and increases productivity.`,
    uk: `RPA може інтегруватися з системами обробки даних, спрощуючи процеси
            передачі та обміну інформацією між різними платформами, що прискорює
            робочі потоки та підвищує продуктивність.`,
  },
  'rpa-list-2-title-6-mob': {
    en: `Automation of routine tasks based on data analysis:`,
    uk: ` Автоматизація рутинних завдань на основі аналізу даних:`,
  },
  'rpa-list-2-discription-6-mob': {
    en: `Integrating RPA with Data Science allows you to create intelligent
             robots that automate not only routine processes, but also perform them
             tasks based on data analysis and processing.`,
    uk: ` Інтеграція RPA з Data Science дозволяє створювати інтелектуальні
            роботи, які автоматизують не тільки рутинні процеси, але й виконують
            завдання на основі аналізу та обробки даних.`,
  },
  'rpa-section-title-3': {
    en: `RPA Consulting Services`,
    uk: `Консалтингові послуги RPA`,
  },
  'rpa-services-seotext-consult-1': {
    en: ` At MBIT Consultants, we go the extra mile with our robotic process
      automation consulting services. Our team of experts works closely with you
      to identify areas where RPA can make the most significant impact.`,
    uk: `У MBIT Consultants ми докладаємо більше зусиль, надаючи консультаційні
      послуги з автоматизації роботизованих процесів. Наша команда експертів
      тісно співпрацює з вами, щоб визначити сфери, де RPA може зробити найбільш
      значний вплив.`,
  },
  'rpa-services-seotext-consult-2': {
    en: `  RPA it's a game-changer. The power of robotic automation process lies in
      its ability to adapt and learn. As your business evolves, so do our RPA
      solutions. This scalability ensures that you are always at the forefront
      of technological advancements, ready to face the challenges of tomorrow.`,
    uk: `  Розробка robotic process automation змінює правила гри. Потужність
      роботизованого процесу автоматизації полягає в його здатності адаптуватися
      та навчатися. З розвитком вашого бізнесу розвиваються і наші рішення RPA.
      Ця масштабованість гарантує, що ви завжди в авангарді технологічних
      досягнень, готові протистояти викликам завтрашнього дня.`,
  },
  'rpa-services-seotext-consult-3': {
    en: ` One concern many businesses have when adopting new technologies is the
      disruption it may cause. With MBIT Consultants, you can put those worries
      to rest. Our RPA solutions are designed for seamless integration, ensuring
      that the implementation process is smooth, and your business experiences a
      minimal learning curve.`,
    uk: `Багато підприємств хвилюються, коли впроваджують нові технології, це збої,
      які це може спричинити. З MBIT Consultants ви можете позбутися цих турбот.
      Наша розробка RPA створена для бездоганної інтеграції, що гарантує, що
      процес впровадження буде плавним, а ваш бізнес потребуватиме мінімального
      навчання.`,
  },
  'hero__title-idp': {
    en: ` IDP Provider for Advanced Document Processing Solutions`,
    uk: `Інтелектуальна обробка документів для високотехнологічних рішень у сфері обробки документів`,
  },
  'hero__description-idp': {
    en: `  MBIT Consultants stands at the forefront of intelligent document
        processing (IDP) services, offering cutting-edge solutions that leverage
        a robust set of technologies to streamline document management and data
        extraction processes. As an IDP provider, we specialize in delivering
        comprehensive IDP solutions tailored to meet the unique needs of our
        clients.`,
    uk: `MBIT Consultants визначається на передовому фронті послуг із інтелектуальної обробки документів, пропонуючи передові рішення, які використовують потужний технологічний набір для оптимізації процесів управління документами та екстракції даних. Ми спеціалізуємося на наданні комплексних рішень, адаптованих до унікальних потреб наших клієнтів.`,
  },
  'idp-seo-text-1': {
    en: `Our leading service utilizing artificial intelligence is Intelligent Document Processing (IDP).`,
    uk: `Нашою провідною послугою з використанням штучного інтелекту є – Інтелектуальна обробка документів (IDP)`,
  },
  'idp-seo-text-2': {
    en: `IDP (Intelligent Document Processing) is an information processing automation system that conducts scanning, text recognition, data extraction, sorting, and organizing of pertinent information from voluminous data into usable formats. This technology is capable of handling various document types, including paper documents, PDF files, Word documents, spreadsheets, and other formats, facilitating the recognition, analysis, and utilization of essential information from these sources.`,
    uk: `IDP (Intelligence documents processing) - це система автоматизації обробки інформації, яка проводить сканування, розпізнавання тексту, видобування даних, сортування та організацію значущої інформації з об'ємних даних у доступні для використання формати. Ця технологія може обробляти різноманітні типи документів, такі як паперові документи, PDF-файли, документи Word, електронні таблиці та інші формати, сприяючи розпізнаванню, аналізу та використанню важливої інформації з цих джерел.`,
  },
  'idp-seo-text-3': {
    en: `The primary function of IDP is to extract valuable information from large datasets without human intervention, enabling further convenient handling and utilization of this information.`,
    uk: `Основною функцією IDP є отримання цінної інформації з великих масивів даних без участі людини, з метою подальшої зручної роботи з нею.`,
  },
  'idp-seo-text-4': {
    en: `IDP services involve several key steps, starting with intelligent document
      recognition. The process incorporates complex deep-learning AI technology
      capable of scanning and classifying documents in up to 190 languages.`,
    uk: `Інтелектуальна обробка документів включає кілька ключових етапів, починаючи з інтелектуального визначення документів. Процес використовує складну технологію глибокого навчання штучного інтелекту, здатного сканувати та класифікувати документи на до 190 мовах.`,
  },
  'idp-seo-text-5': {
    en: `Following classification, the intelligent document automation software
      employs cognitive AI technologies to extract valuable data. MBIT
      Consultants organizes this data, presenting it in a format that is easily
      accessible and beneficial to your organization.`,
    uk: `Після класифікації програмне забезпечення для інтелектуальної обробки документів використовує когнітивні технології штучного інтелекту для екстракції цінних даних. MBIT організовує ці дані, представляючи їх у форматі, який легко доступний та корисний для вашої організації.`,
  },
  'idp-seo-text-6': {
    en: `For organizations dealing with large volumes of structured,
      semi-structured, or unstructured data, MBIT Consultants offers an
      invaluable investment in intelligent document processing as part of a
      robust business process management strategy.`,
    uk: `Для організацій, які мають великі обсяги структурованих, напівструктурованих чи неструктурованих даних, інтелектуальна обробка документів є інвестицією, яка варта уваги як частина стратегії управління бізнес-процесами.`,
  },
  'idp-seo-text-7': {
    en: `Choose MBIT Consultants for unparalleled intelligent document processing
      services, combining technological innovation with industry expertise.
      Experience the future of document processing with us.`,
    uk: `MBIT Consultants надає якісні послуги, поєднуючи технологічну інновацію з професійним досвідом галузі. Відчуйте майбутнє обробки документів разом із нами.`,
  },
  'use-case__title': {
    en: `Examples of IDP Usage`,
    uk: `Приклади використання IDP`,
  },
  'use-case__item-text-1': {
    en: `- can automate accounts payable functions. Classify Bills of Services rendered and swiftly retrieve information from them just by searching.`,
    uk: `- можуть
          автоматизувати функції обробки рахунків до сплати. Класифікувати Акти
          наданих послуг та отримувати швидко інформацію з них просто по пошуку.`,
  },
  'use-case__item-text-accent-1': {
    en: `Finance Departments`,
    uk: `Відділи фінансів`,
  },
  'use-case__item-text-2': {
    en: `- can manage permits, issue electronic documents, and process requests using software for intelligent document processing.`,
    uk: `- можуть
          керувати дозволами, видавати електронні документи та обробляти заявки
          за допомогою програмного забезпечення для інтелектуальної обробки
          документів.`,
  },
  'use-case__item-text-accent-2': {
    en: `Government Institutions`,
    uk: `Державні установи`,
  },
  'use-case__item-text-3': {
    en: `- can process, archive legal data, and manage it with greater accuracy. Extract specific information (such as discounts, payment deferrals, contract durations, etc.) from a large number of contracts.`,
    uk: ` - можуть
          обробляти, архівувати юридичні дані та керувати ними з більшою
          точністю. Видобувати певну інформацію (наприклад знижки,
          відтермінування оплати, термін дії договору та інше) з великої
          кількості договорів.`,
  },
  'use-case__item-text-accent-3': {
    en: `Legal Department`,
    uk: `Юридичний відділ`,
  },
  'use-case__item-text-4': {
    en: `- can use automated document processing to handle claims, detect fraud, sort insurance policies, and expedite document processing within your organization.`,
    uk: `- можуть
          використовувати автоматизовану обробку документів для обробки
          претензій, виявлення шахрайства, сортування страхових полісів і
          прискорення обробки документів у вашій організації.`,
  },
  'use-case__item-text-accent-4': {
    en: `Insurance Companies`,
    uk: `Страхові компанії`,
  },
  'use-case__item-text-5': {
    en: `- can process resumes, select employees, and handle employee surveys and other personnel data using IDP.`,
    uk: `- можуть
          обробляти резюме, відбирати співробітників і обробляти опитування
          співробітників та інші кадрові дані за допомогою IDP.`,
  },
  'use-case__item-text-accent-5': {
    en: `Human Resources Departments`,
    uk: `Відділи кадрів`,
  },
  'use-case__item-text-6': {
    en: `- widely apply IDP for working with Material Safety Data Sheets (MSDS).`,
    uk: `- широко застосовують IDP для роботи з Сертифікатами безпечності
          матеріалу (Safety data sheet)`,
  },
  'use-case__item-text-accent-6': {
    en: `Chemical and Other Industries`,
    uk: `Хімічна та інша промисловість`,
  },
  'idp-flow__title': {
    en: `How it works:`,
    uk: `Як це працює:`,
  },
  'step__title-1': {
    en: `Document Capture`,
    uk: `Захоплення документів (Document Capture)`,
  },
  'step__text-1': {
    en: `Gathering and capturing documents from various sources such as scanners, electronic forms, email, and others.`,
    uk: `Збір та захоплення документів з різних джерел, таких як сканери,
            електронні форми, електронна пошта та інші.`,
  },
  'step__title-2': {
    en: `Processing`,
    uk: `Обробка (Processing)`,
  },
  'step__text-2': {
    en: `Document Processing - Text recognition (OCR), data extraction, document classification, and format conversion.`,
    uk: `Обробка документів - розпізнавання тексту (OCR), вилучення даних,
            класифікація документів та перетворення форматів.`,
  },
  'step__title-3': {
    en: `Indexing and Classification`,
    uk: `Індексація та класифікація (Indexing)`,
  },
  'step__text-3': {
    en: `The process of assigning key metadata to each document for convenient search and organization purposes later on.`,
    uk: `Процес присвоєння ключових метаданих кожному документу для
            подальшого зручного пошуку та організації.`,
  },
  'step__title-4': {
    en: `Extraction and Storage`,
    uk: `Екстрагування та Зберігання (Storage)`,
  },
  'step__text-4': {
    en: `Documents can be stored digitally in a document management system, cloud storage, etc.`,
    uk: ` Документи можуть зберігатися у цифровому вигляді в системі
            управління документами, хмарному сховищі тощо.`,
  },
  'step__title-5': {
    en: `Accessing information within the documen`,
    uk: `Доступ до роботи з інформацією з документу`,
  },
  'step__text-5': {
    en: `The ability to efficiently and quickly interact, catalog, classify, implement, and/or otherwise utilize data in a convenient format.`,
    uk: `  Можливість ефективно та швидко взаємодіяти, каталогізувати,
            класифікувати, імплементувати та/або в інший спосіб використовувати
            дані у зручному форматі.`,
  },
  'step__title-6': {
    en: `Search and Retrieval`,
    uk: `Пошук і вилучення (Search and Retrieval)`,
  },
  'step__text-6': {
    en: `The capability to efficiently search and retrieve documents based on various criteria.`,
    uk: `Можливість ефективного пошуку та вилучення документів за різними
            критеріями.`,
  },
  'idp-benefits-item__title-1': {
    en: `Integrated workflow and automation`,
    uk: `Інтегрований робочий процес та робототехніка`,
  },
  'idp-benefits-item__text-1': {
    en: `
Looking into the future, we propose integrating workflow and robotics to accelerate and fully automate document processing. This leads to increased efficiency and a seamless solution from start to finish.`,
    uk: ` Дивлячись у майбутнє, ми пропонуємо інтеграцію робочого процесу та
          робототехніки для прискорення та повністю автоматизованої обробки
          документів. Це призводить до підвищення ефективності та безшовного
          рішення з початку до кінця.`,
  },
  'idp-benefits-item__title-2': {
    en: `Specialization in complex industrial documents`,
    uk: `Спеціалізація на складних промислових документах`,
  },
  'idp-benefits-item__text-2': {
    en: `Expertise covers dealing with complex industrial documents. SDS (Safety Data Sheet) is designed to work with intricate structures, setting us apart from competitors who might struggle with less complex documents.`,
    uk: `Експертиза охоплює роботу зі складними промисловими документами. SDS
          призначений для роботи з витонченою структурою, що відрізняє нас від
          конкурентів, які можуть мати труднощі з менш складними документами.`,
  },
  'idp-benefits-item__title-3': {
    en: `Cloud Migration and Service Launch`,
    uk: `Міграція до хмари та запуск сервісу`,
  },
  'idp-benefits-item__text-3': {
    en: `We assist in migrating to the cloud and launching services, configuring them to revolve around the core service - Intelligent Document Processing. This approach ensures a smooth transition to cloud environments, enhancing scalability and accessibility.`,
    uk: `Ми допомагаємо у міграції до хмари та запуску сервісів, відповідно
          налаштовуючи їх на основний сервіс - інтелектуальну обробку
          документів. Цей підхід забезпечує плавний перехід до хмарних
          середовищ, підвищуючи масштабованість та доступність.`,
  },
  'idp-benefits-item__title-4': {
    en: `Integration of Computer Visioning`,
    uk: `Інтеграція Computer Visioning`,
  },
  'idp-benefits-item__text-4': {
    en: `The central element of the service is the integration of computer visioning. This technology enhances the document identification phase using deep learning artificial intelligence for document scanning and classification. Not only does it improve accuracy, but it also enables the system to "see" and interpret documents, akin to a human data processor.`,
    uk: ` Центральним елементом сервісу є інтеграція computer visioning. Ця
          технологія поліпшує фазу визначення документа за допомогою глибокого
          навчання штучного інтелекту для сканування та класифікації документів.
          Це не тільки покращує точність, але й дозволяє системі "бачити" та
          інтерпретувати документи, схоже на людського працівника із обробки
          даних.`,
  },
  'hero__title-computer-vision': {
    en: `Revolutionizing Businesses with Advanced Computer Vision Services`,
    uk: `Революція в бізнесі завдяки передовим послугам з комп'ютерного бачення`,
  },
  'hero__description-computer-vision': {
    en: ` At MBIT Consultants, we take pride in our computer vision development
        services, where we harness the power of innovative technologies to
        deliver tailored solutions for businesses across diverse industries. Our
        specialized services encompass every aspect of computer vision, from
        algorithm development to deep learning model creation, ensuring seamless
        integration into existing systems. We are committed to enhancing
        efficiency, accuracy, and unlocking new opportunities for businesses,
        providing customized solutions that align with unique needs and goals.`,
    uk: `У MBIT пишаємося нашими передовими послугами з комп'ютерного бачення. Ми використовуємо силу інноваційних технологій, щоб надавати індивідуальні рішення для підприємств у різноманітних галузях. Наші спеціалізовані послуги охоплюють кожен аспект комп'ютерного бачення, від розробки алгоритмів до створення моделей глибокого навчання, забезпечуючи безперешкодну інтеграцію в існуючі системи.`,
  },
  'computer-vision-seo-text-1': {
    en: `Computer Vision Services combined with Intelligent Document Processing
      (IDP) entail the integration of computer vision techniques and AI-driven
      document processing to analyze, interpret, and extract information from
      documents, images, or videos. Here's an outline of how these services
      typically work together:`,
    uk: `Служби комп'ютерного зору з інтелектуальною обробкою документів" (Computer Vision Services with Intelligent Document Processing) передбачають поєднання технік комп'ютерного зору та обробки документів з використанням штучного інтелекту, щоб аналізувати, інтерпретувати та видобувати інформацію з документів, зображень або відео. Ось загальний огляд того, як працюють ці служби:`,
  },
  'computer-vision__title': {
    en: `Key Advantages of Choosing MBIT Consultants`,
    uk: `Основні переваги вибору MBIT Consultants`,
  },
  'computer-vision-seo-text-2': {
    en: `By combining computer vision capabilities with intelligent document processing techniques, these services aim to automate and streamline document handling, extraction, and analysis, offering efficiency and accuracy in information extraction and decision-making processes.`,
    uk: `Поєднуючи можливості комп'ютерного зору з інтелектуальною обробкою документів, ці служби спрямовані на автоматизацію та оптимізацію обробки, видобуття і аналізу документів, що надає ефективність та точність у витягуванні інформації та процесів прийняття рішень.`,
  },
  'cv-benefits__title-1': {
    en: `Computer Vision Algorithms:`,
    uk: `Алгоритми комп'ютерного зору:`,
  },
  'cv-benefits__title-2': {
    en: `Intelligent Document Processing:`,
    uk: `Інтелектуальна обробка документів:`,
  },
  'cv-benefits__title-3': {
    en: `Integration of Machine Learning Models:`,
    uk: `Інтеграція моделей машинного навчання:`,
  },
  'cv-benefits__subtitle-1': {
    en: `Document Classification:`,
    uk: `Класифікація документів:`,
  },
  'cv-benefits__text-1': {
    en: `Using computer vision algorithms, documents can be classified into different types or categories based on visual cues and patterns. This can help in sorting and routing documents effectively.`,
    uk: `За допомогою алгоритмів комп'ютерного зору можна класифікувати
            документи за різними типами або категоріями на основі візуальних
            ознак і паттернів. Це допомагає ефективно сортувати та
            маршрутизувати документи.`,
  },
  'cv-benefits__subtitle-2': {
    en: `Layout Analysis:`,
    uk: `Аналіз макету:`,
  },
  'cv-benefits__text-2': {
    en: ` Algorithms can analyze the structure and layout of documents to identify key sections, headers, footers, paragraphs, and other components. This helps in extracting relevant information.`,
    uk: ` Алгоритми можуть аналізувати структуру та макет документів для
            виявлення ключових розділів, заголовків, нижніх колонтитулів,
            абзаців та інших елементів. Це допомагає в видобутті необхідної
            інформації.`,
  },
  'cv-benefits__subtitle-3': {
    en: `Object Recognition:`,
    uk: `Розпізнавання об'єктів:`,
  },
  'cv-benefits__text-3': {
    en: `Identifying and extracting specific objects, such as logos, stamps, signatures, or specific shapes within documents, images, or videos.`,
    uk: `Видобуття конкретних об'єктів, таких як логотипи, печатки, підписи
            або конкретні форми в документах, зображеннях або відео.`,
  },
  'cv-benefits__subtitle-4': {
    en: `Optical Character Recognition (OCR):`,
    uk: `Оптичне розпізнавання символів (OCR):`,
  },
  'cv-benefits__text-4': {
    en: ` This technology enables the extraction of text from images or scanned documents. OCR identifies characters and converts them into machine-readable text.`,
    uk: `Ця технологія дозволяє видобувати текст з зображень або сканованих
            документів. OCR розпізнає символи і перетворює їх в
            машинночитабельний текст.`,
  },
  'cv-benefits__subtitle-5': {
    en: `Information Extraction:`,
    uk: `Видобуття інформації:`,
  },
  'cv-benefits__text-5': {
    en: `Beyond basic text extraction, IDP involves the extraction of structured information like names, dates, numbers, addresses, etc., from the recognized text to make it usable for further processing.`,
    uk: `Поза базовим видобуттям тексту, IDP включає видобуття структурованої
            інформації, такої як імена, дати, номери, адреси і т. д., з
            розпізнаного тексту для подальшого використання.`,
  },
  'cv-benefits__subtitle-6': {
    en: `Data Validation and Verification:`,
    uk: `Валідація та перевірка даних:`,
  },
  'cv-benefits__text-6': {
    en: `The extracted information can be validated against predefined rules or databases to ensure accuracy and authenticity.`,
    uk: `Видобута інформація може бути перевірена за попередньо визначеними
            правилами або базами даних для забезпечення точності та
            достовірності.`,
  },
  'cv-benefits__subtitle-7': {
    en: `Output and Action:`,
    uk: `Вивід та дії:`,
  },
  'cv-benefits__text-7': {
    en: `The output could involve generating structured data, reports, triggering workflows, or feeding the information into other systems for further action or analysis.`,
    uk: `Результатом може бути створення структурованих даних, звітів,
            спрацювання робочих процесів або передача інформації в інші системи
            для подальших дій чи аналізу.`,
  },
  'cv-benefits__subtitle-8': {
    en: `Continuous Improvement:`,
    uk: `Постійне вдосконалення:`,
  },
  'cv-benefits__text-8': {
    en: `These systems typically undergo continuous monitoring and refinement to improve accuracy, efficiency, and adaptability to changing document formats or requirements.`,
    uk: `Ці системи зазвичай проходять постійний моніторинг та вдосконалення
            для покращення точності, ефективності та пристосованості до змін
            форматів документів чи вимог.`,
  },
  'cv-benefits__subtitle-9': {
    en: `Training Models:`,
    uk: `Тренування моделей:`,
  },
  'cv-benefits__text-9': {
    en: `Machine learning models can be trained to improve accuracy and efficiency in document processing. These models learn from labeled data to enhance recognition and extraction capabilities.`,
    uk: ` Моделі машинного навчання можуть бути навчені для покращення
            точності та ефективності обробки документів. Ці моделі вивчаються з
            маркованих даних для покращення можливостей розпізнавання та
            видобуття інформації.`,
  },
  'cv-benefits__subtitle-10': {
    en: `Adaptive Learning: `,
    uk: `Адаптивне навчання:`,
  },
  'cv-benefits__text-10': {
    en: `Systems might incorporate feedback mechanisms where corrections or human input refine the algorithms, improving their performance over time.`,
    uk: `Системи можуть включати механізми зворотного зв'язку, де виправлення
            або внесення людського впливу вдосконалюють алгоритми, покращуючи їх
            ефективність з часом.`,
  },
  'cv-benefits__subtitle-11': {
    en: `Data Fusion and Decision Making:`,
    uk: `Фузія даних та прийняття рішень:`,
  },
  'cv-benefits__text-11': {
    en: `After extracting information, these systems correlate and consolidate the extracted data points to derive insights or make decisions based on the processed information.`,
    uk: ` Після видобуття інформації системи узгоджують та об'єднують
            витягнуті дані для отримання інсайтів або прийняття рішень на основі
            обробленої інформації.`,
  },
  'hero__title-data-scince': {
    en: `MBIT Consultants' Data Science and Analytics Services`,
    uk: `Дата саєнс та дата аналітика від MBIT Consultants`,
  },
  'hero__description-data-scince': {
    en: `Businesses are increasingly recognizing the transformative power of data. Harnessing the potential hidden within vast datasets is not only a competitive advantage but a necessity for staying ahead in today's market. We stand at the forefront of this data revolution, offering comprehensive services tailored to meet the unique needs of each client.`,
    uk: `Для сучасного бізнесу використання потенціалу, прихованого у обширних
        масивах даних, стає не лише конкурентною перевагою, але й необхідністю
        для утримання лідерства на ринку. MBIT пропонує комплексні послуги,
        спеціально адаптовані для відповіді на унікальні потреби кожного
        клієнта.`,
  },
  'seo-text-title-1': {
    en: `ML & AI Services: Transforming Raw Data into Actionable Insights`,
    uk: `ML & AI послуги: трансформація сирої інформації в дієві інсайти`,
  },
  'data-scince-seo-text-1': {
    en: `At the core of our data science and analytics suite is our ML & AI Services. Leveraging advanced technologies, we enable businesses to unlock the true potential of their data. From predictive modeling to pattern recognition and automated decision-making, our ML & AI Services play a crucial role in transforming data into actionable insights. Whether optimizing business processes or gaining a competitive edge through intelligent automation, these services stand as a cornerstone in our commitment to delivering solutions that is right for you.`,
    uk: `У самому серці нашого комплексу послуг у сфері Data Science та Аналітики
      знаходяться наші ML & AI послуги. Використовуючи передові технології, ми
      дозволяємо бізнесам розкрити справжній потенціал своїх даних. Від
      прогнозування моделей до розпізнавання патернів та автоматизованого
      прийняття рішень. Неважливо, чи ви хочете оптимізувати бізнес-процеси, чи
      отримати конкурентну перевагу завдяки інтелектуальній автоматизації — ці
      послуги є важливим елементом нашого зобов'язання до надання передових
      рішень у сфері Data Science та Аналітики.`,
  },
  'seo-text-title-2': {
    en: `Tailored Solutions for Every Challenge`,
    uk: `Індивідуальні рішення для кожного виклику`,
  },
  'data-scince-seo-text-2': {
    en: `Our company takes pride in offering holistic data analytics solutions and data science services. We collaborate closely with clients to understand their unique objectives and challenges, providing insights and strategies that align with their business goals. Our team's expertise spans various industries, ensuring that our solutions are data-driven and industry-specific.`,
    uk: ` Ми пишаємось тим, що пропонуємо комплексні рішення в галузі аналітики
      даних та послуг з Data Science. MBIT тісно співпрацює з клієнтами, щоб
      зрозуміти їх унікальні цілі та виклики, надаючи інсайти та стратегії, які
      відповідають їхнім бізнес-цілям. Експертиза нашої команди охоплює різні
      галузі, забезпечуючи, що наші рішення ґрунтуються на даних та специфічні
      для галузі.`,
  },
  'ds-benefits-title-1': {
    en: `Expertise and Innovation`,
    uk: `Експертиза та інновації`,
  },
  'ds-benefits-text-1': {
    en: `Our team comprises skilled data scientists and analysts passionate about delivering innovative solutions tailored to your business goals.`,
    uk: `Наша команда складається з досвідчених фахівців з Data Science, які
            працюють над інноваційними рішеннями, спрямованими на ваші
            бізнес-цілі.`,
  },
  'ds-benefits-title-2': {
    en: `Customized Solutions`,
    uk: `Індивідуальні рішення`,
  },
  'ds-benefits-text-2': {
    en: `We understand that every business is unique. Hence, we craft bespoke solutions aligned with your specific requirements.`,
    uk: `Ми розуміємо, що кожен бізнес унікальний. Тому ми створюємо індивідуальні рішення, які відповідають вашим конкретним вимогам.`,
  },
  'ds-benefits-title-3': {
    en: `Quality and Accuracy`,
    uk: `Якість та точність`,
  },
  'ds-benefits-text-3': {
    en: `Ensuring the highest quality standards, our focus lies on delivering accurate and reliable insights to drive your business forward.`,
    uk: ` Ми гарантуємо найвищі стандарти якості, зосереджуючись на достовірності та точності інсайтів для розвитку вашого бізнесу.`,
  },
  'ds-benefits-title-4': {
    en: `End-to-End Support`,
    uk: `Повна підтримка`,
  },
  'ds-benefits-text-4': {
    en: `From data collection to analysis and implementation, we provide comprehensive support at every stage of your data journey.`,
    uk: `Від збору даних до аналізу та впровадження - ми забезпечуємо комплексну підтримку на всіх етапах вашого шляху з даними.`,
  },
  'section__title-data-scince': {
    en: `Why choose us?`,
    uk: `Чому обирати нас?`,
  },
  'ds__services-title-1': {
    en: `Data Analysis and Interpretation:`,
    uk: `Аналіз та інтерпретація даних:`,
  },
  'ds__services-text-1': {
    en: `Harnessing the power of advanced analytics, we dive deep into your data, uncovering patterns, trends, and actionable insights to aid informed decision-making.`,
    uk: `Використовуючи потужні аналітичні інструменти, ми детально аналізуємо ваші дані, виявляємо патерни, тенденції та практичні інсайти для підтримки обґрунтованих рішень.`,
  },
  'ds__services-title-2': {
    en: `Predictive Modeling:`,
    uk: `Прогнозування:`,
  },
  'ds__services-text-2': {
    en: `Leveraging machine learning algorithms, we build predictive models to forecast trends, anticipate customer behavior, and optimize business strategies.`,
    uk: `Застосовуючи алгоритми машинного навчання, ми розробляємо прогностичні моделі для передбачення тенденцій, аналізу поведінки клієнтів та оптимізації стратегій бізнесу.`,
  },
  'ds__services-title-3': {
    en: `Data Visualization: `,
    uk: `Візуалізація даних:`,
  },
  'ds__services-text-3': {
    en: `Translating complex data into compelling visual stories, our data visualization techniques help in presenting insights in a clear, intuitive manner for better understanding and decision-making.`,
    uk: `Перетворюючи складні дані в переконливі візуальні історії, наші методи візуалізації даних допомагають представляти інсайти зрозуміло та лаконічно для кращого розуміння та прийняття рішень.`,
  },
  'ds__services-title-4': {
    en: `Natural Language Processing (NLP):`,
    uk: `Обробка природної мови (Natural Language Processing, NLP):`,
  },
  'ds__services-text-4': {
    en: `Through NLP, we derive valuable insights from unstructured text data, enabling sentiment analysis, topic modeling, and information extraction.`,
    uk: `За допомогою NLP ми отримуємо цінні інсайти з неструктурованих текстових даних, що дозволяє аналізу відчуттів, моделювання тем та видобуття інформації.`,
  },
  'ds__services-title-5': {
    en: `Machine Learning Solutions:`,
    uk: `Рішення з машинного навчання:`,
  },
  'ds__services-text-5': {
    en: `Crafting custom machine learning solutions tailored to your business needs, we automate processes, improve efficiency, and enhance productivity.`,
    uk: `Розробка індивідуальних рішень з машинного навчання, які відповідають вашим бізнес-потребам, автоматизують процеси та підвищують продуктивність.`,
  },
  'ds__services-title-6': {
    en: `Big Data Analytics:`,
    uk: `Аналітика великих обсягів даних:`,
  },
  'ds__services-text-6': {
    en: `Handling large volumes of structured and unstructured data, we extract meaningful information to uncover opportunities and optimize operations.`,
    uk: `Обробка великих обсягів структурованих та неструктурованих даних для видобуття значущої інформації для виявлення можливостей та оптимізації операцій.`,
  },
  'terms-title': {
    en: `1. General Provisions`,
    uk: `1. Загальні положення`,
  },
  'terms-discription-1': {
    en: `This privacy policy explains how we collect, use, and protect your
        personal data on our website [https://www.mbit-consultants.com/]. We
        prioritize your privacy and ensure the security of your personal data in
        accordance with applicable laws.`,
    uk: `Ця політика конфіденційності пояснює, як ми збираємо, використовуємо та захищаємо ваші персональні дані на нашому веб-сайті [https://www.mbit-consultants.com/]. Ми надаємо пріоритет вашій конфіденційності та забезпечуємо безпеку вашої особистої інформації відповідно до чинного законодавства.`,
  },
  'terms-title-2': {
    en: `2. Collection and Use of Personal Data`,
    uk: `2. Збір та використання особистих даних`,
  },
  'terms-discription-2': {
    en: `We may collect personal data from users who interact with our website,
        including names, email addresses, and other information you voluntarily
        provide to us through the "Contact Us" form at
        [info@mbit-consultants.com] to provide you with services and respond to
        your inquiries. We use your personal data solely for the purposes for
        which it was provided. We do not disclose, transfer, or sell your
        personal data to third parties without your consent.`,
    uk: `Ми можемо збирати особисті дані від користувачів, які взаємодіють з нашим веб-сайтом, включаючи імена, електронні адреси та іншу інформацію, яку ви надаєте добровільно через форму "Зв'яжіться з нами" за адресою [info@mbit-consultants.com], щоб надавати вам послуги та відповідати на ваші запити. Ми використовуємо ваші особисті дані виключно для тих цілей, для яких вони були надані. Ми не розголошуємо, не передаємо та не продаемо ваші особисті дані третім сторонам без вашої згоди.`,
  },
  'terms-title-3': {
    en: `3. Protection of Personal Data`,
    uk: `3. Захист особистих даних`,
  },
  'terms-discription-3': {
    en: ` We take necessary measures to protect your personal data from
        unauthorized access, disclosure, alteration, or destruction. We use
        standard security protocols and technologies to ensure the security of
        your information.`,
    uk: `Ми вживаємо необхідних заходів для захисту ваших особистих даних від несанкціонованого доступу, розголошення, зміни або знищення. Ми використовуємо стандартні протоколи безпеки та технології, щоб забезпечити безпеку вашої інформації.`,
  },
  'terms-title-4': {
    en: `4. Changes to the Privacy Policy`,
    uk: `4. Зміни у Політиці конфіденційності`,
  },
  'terms-discription-4': {
    en: `We may update this privacy policy from time to time to reflect changes
        in our data collection and protection practices. Please periodically
        check this page for updates.`,
    uk: `Ми можемо час від часу оновлювати цю політику конфіденційності, щоб відобразити зміни в наших практиках збору та захисту даних. Будь ласка, періодично перевіряйте цю сторінку для оновлень.`,
  },
  'terms-title-5': {
    en: `5. Contact Us`,
    uk: `5. Зв'яжіться з нами`,
  },
  'terms-discription-5': {
    en: ` If you have any questions or concerns regarding this privacy policy,
        please contact us at [info@mbit-consultants.com].`,
    uk: `Якщо у вас виникли будь-які питання чи зауваження щодо цієї політики конфіденційності, будь ласка, зв'яжіться з нами за адресою [info@mbit-consultants.com].`,
  },
  'terms-of-use-title': {
    en: `Terms of Use`,
    uk: `Умови використання`,
  },
  'terms-of-use-title-1': {
    en: `1. Acceptance of Terms`,
    uk: `1. Прийняття умов`,
  },
  'terms-discription-6': {
    en: `Users who visit and interact with our website must accept these terms of
        use. They are mandatory for you to adhere to when using our website.`,
    uk: `Користувачі, які відвідують та взаємодіють з нашим веб-сайтом, повинні прийняти ці умови використання. Вони обов'язкові для дотримання вами при використанні нашого веб-сайту.`,
  },
  'disclaimer-title': {
    en: `2. Disclaimer`,
    uk: `2. Відмова від відповідальності`,
  },
  'terms-discription-7': {
    en: ` We are not liable for any damages that may arise from using this website
        or related services.`,
    uk: `Ми не несемо відповідальності за будь-які збитки, які можуть виникнути в результаті використання цього веб-сайту або пов'язаних послуг.`,
  },
  'usage-rules-title': {
    en: `3. Website Usage Rules`,
    uk: `3. Правила використання веб-сайту`,
  },
  'terms-discription-8': {
    en: `You are required to comply with all rules and limitations established by
        our website. Any illegal or abusive activity on our website is
        prohibited.`,
    uk: `Вам необхідно дотримуватися всіх правил та обмежень, встановлених нашим веб-сайтом. Будь-яка незаконна або зловживальна діяльність на нашому веб-сайті заборонена.`,
  },
  'termination-title': {
    en: `4. Termination of Access`,
    uk: `4. Припинення доступу`,
  },
  'terms-discription-9': {
    en: `We reserve the right to terminate or restrict access to our website at
        our discretion, without prior notice or explanation.`,
    uk: `Ми залишаємо за собою право припинити або обмежити доступ до нашого веб-сайту за нашим власним розсудом, без попереднього повідомлення або пояснень.`,
  },
  'terms-contact-us-title': {
    en: `5. Contact Us`,
    uk: `5. Зв'яжіться з нами`,
  },
  'terms-discription-10': {
    en: ` If you have any questions or concerns regarding these terms of use,
        please contact us at [info@mbit-consultants.com]. Please note that these
        are general templates and should be further customized to meet the
        specific needs and legal requirements of your website and jurisdiction.
        It's advisable to consult with a legal professional to ensure compliance
        with applicable laws and regulations.`,
    uk: `Якщо у вас виникли питання або зауваження щодо цих умов використання, будь ласка, зв'яжіться з нами за адресою [info@mbit-consultants.com].`,
  },
  'conditions-title': {
    en: `Terms and conditions`,
    uk: `Умови та положення`,
  },
  'conditions-discription-1': {
    en: ` These terms and conditions ("Agreement" set forth the general terms and
        conditions of your use of the mbit-consultants.com website ("Website" or
        "Service") and any of its related products and services (collectively,
        "Services"). This Agreement is legally binding between you ("User",
        "you" or "your") and this Website operator ("Operator", "we", "us" or
        "our"). If you are entering into this Agreement on behalf of a business
        or other legal entity, you represent that you have the authority to bind
        such entity to this Agreement, in which case the terms "User", "you" or
        "your" shall refer to such entity. If you do not have such authority, or
        if you do not agree with the terms of this Agreement, you must not
        accept this Agreement and may not access and use the Website and
        Services. By accessing and using the Website and Services, you
        acknowledge that you have read, understood, and agree to be bound by the
        terms of this Agreement. You acknowledge that this Agreement is a
        contract between you and the Operator, even though it is electronic and
        is not physically signed by you, and it governs your use of the Website
        and Services.`,
    uk: `Ці умови та положення ("Угода") встановлюють загальні умови та положення щодо використання вами веб-сайту mbit-consultants.com ("Веб-сайт" або "Сервіс") та будь-яких його пов'язаних продуктів і послуг (загалом "Послуги"). Ця Угода є юридично обов'язковою між вами ("Користувач", "ви" або "ваш") та оператором цього Веб-сайту ("Оператор", "ми", "нас" або "наш"). Якщо ви укладаєте цю Угоду від імені бізнесу чи іншої юридичної особи, ви підтверджуєте, що маєте повноваження зв'язати таку юридичну особу цією Угодою, у такому випадку терміни "Користувач", "ви" або "ваш" стосуватимуться такої особи. Якщо у вас немає таких повноважень або ви не погоджуєтеся з умовами цієї Угоди, ви повинні відмовитися від прийняття цієї Угоди та не матимете доступу та не будете використовувати Веб-сайт та Послуги. Використовуючи Веб-сайт та Послуги, ви підтверджуєте, що прочитали, зрозуміли та погоджуєтеся з умовами цієї Угоди. Ви визнаєте, що ця Угода є контрактом між вами та Оператором, незважаючи на те, що вона є електронною та не підписана фізично вами, і вона регулює ваше використання Веб-сайту та Послуг.`,
  },
  'conditions-table-of-contents': {
    en: `Table of contents`,
    uk: `Зміст`,
  },
  'conditions-item-1': {
    en: `Changes and amendments`,
    uk: `Зміни та поправки`,
  },
  'conditions-item-2': {
    en: `Links to other resources`,
    uk: `Посилання на інші ресурси`,
  },
  'conditions-item-3': {
    en: `Acceptance of these terms`,
    uk: `Прийняття цих умов`,
  },
  'conditions-item-4': {
    en: `Contacting us`,
    uk: `Зв'язок з нами`,
  },
  'conditions-title-1': {
    en: `Links to other resources`,
    uk: `Посилання на інші ресурси`,
  },
  'conditions-discription-2': {
    en: ` Although the Website and Services may link to other resources (such as
        websites, mobile applications, etc.), we are not, directly or
        indirectly, implying any approval, association, sponsorship,
        endorsement, or affiliation with any linked resource, unless
        specifically stated herein. We are not responsible for examining or
        evaluating, and we do not warrant the offerings of, any businesses or
        individuals or the content of their resources. We do not assume any
        responsibility or liability for the actions, products, services, and
        content of any other third parties. You should carefully review the
        legal statements and other conditions of use of any resource which you
        access through a link on the Website. Your linking to any other off-site
        resources is at your own risk.`,
    uk: `Хоча Веб-сайт та Послуги можуть містити посилання на інші ресурси (такі як веб-сайти, мобільні додатки і т. д.), ми не заявляємо, яким-небудь чином, прямо або опосередковано, про затвердження, асоціацію, спонсорство, підтримку чи афіліацію з будь-яким зв'язаним ресурсом, якщо це не вказано специфічно в цьому документі. Ми не несемо відповідальності за перевірку або оцінку, і ми не гарантуємо пропозицій жодних бізнесів або осіб чи змісту їхніх ресурсів. Ми не припускаємо жодної відповідальності за дії, продукти, послуги та зміст будь-яких інших третіх сторін. Вам слід уважно ознайомитися з юридичними заявами та іншими умовами використання будь-якого ресурсу, до якого ви маєте доступ через посилання на Веб-сайт. Ваше посилання на будь-які інші зовнішні ресурси відбувається на ваш власний ризик.`,
  },
  'conditions-title-2': {
    en: `Changes and amendments`,
    uk: `Зміни та поправки`,
  },
  'conditions-discription-3': {
    en: ` We reserve the right to modify this Agreement or its terms related to
        the Website and Services at any time at our discretion. When we do, we
        will revise the updated date at the bottom of this page. We may also
        provide notice to you in other ways at our discretion, such as through
        the contact information you have provided. An updated version of this
        Agreement will be effective immediately upon the posting of the revised
        Agreement unless otherwise specified. Your continued use of the Website
        and Services after the effective date of the revised Agreement (or such
        other act specified at that time) will constitute your consent to those
        changes.`,
    uk: `Ми залишаємо за собою право змінювати цю Угоду або її умови, що стосуються Веб-сайту та Послуг, у будь-який час за нашим розсудом. Коли ми це робимо, ми відредагуємо дату оновлення внизу цієї сторінки. Ми також можемо надати вам повідомлення іншими способами за нашим розсудом, наприклад, через контактну інформацію, яку ви надали. Оновлена версія цієї Угоди набуде чинності негайно після публікації переглянутої Угоди, якщо інше не буде вказано. Ваше подальше використання Веб-сайту та Послуг після дати набуття чинності переглянутої Угоди (або такого іншого дійства, вказаного на той момент) буде означати вашу згоду на ці зміни.`,
  },
  'conditions-title-3': {
    en: `Acceptance of these terms`,
    uk: `Прийняття цих умов`,
  },
  'conditions-discription-4': {
    en: `We may update this privacy policy from time to time to reflect changes
        in our data collection and protection practices. Please periodically
        check this page for updates.`,
    uk: `Ми можемо час від часу оновлювати цю політику конфіденційності, щоб відобразити зміни в наших практиках збору та захисту даних. Будь ласка, періодично перевіряйте цю сторінку для оновлень.`,
  },
  'conditions-title-4': {
    en: `Contacting us`,
    uk: `Зв'язок з нами`,
  },
  'conditions-discription-5': {
    en: `If you have any questions, concerns, or complaints regarding this
        Agreement, we encourage you to contact us using the details below:`,
    uk: `Якщо у вас виникли питання, зауваження чи скарги щодо цієї Угоди, ми радимо зв'язатися з нами за наступними контактними даними:`,
  },
  'conditions-updated': {
    en: `This document was last updated on April 23, 2024`,
    uk: `Цей документ був оновлений останній раз 23 квітня 2024 року.`,
  },
  'mbot-hero-title': {
    en: `IDP-Solutions for your business`,
    uk: `M-BOT - технологічне рішення у сфері інтелектуальної обробки документів
        для вашого бізнесу`,
  },
  'mbot-hero-description': {
    en: `Automated data extraction from your documents with the latest
         AI technologies`,
    uk: `Автоматизоване вилучення даних з ваших документів за допомогою новітніх
        технологій AI`,
  },
  'nav-contact-us-mbot': {
    en: `Contact Us`,
    uk: `Зв'яжіться з нами`,
  },
  'mbot-benefits__title': {
    en: `We design our solution to allow an easy 
and fast extraction of data using ML 
algorithms.`,
    uk: ` Ми розробляємо наше рішення так, щоб забезпечити легке і швидке вилучення
      даних за допомогою алгоритмів машинного навчання.`,
  },
  'mbot-benefits__description': {
    en: `Companies increasingly handle vast 
amounts of data. IDP (Intelligent 
Document processing) streamlines 
processing, enabling efficient mass-data 
handling.
`,
    uk: `Компанії все частіше обробляють величезні обсяги даних. IDP
      (інтелектуальна обробка документів) оптимізує обробку, забезпечуючи
      ефективну обробку масових даних.`,
  },
  'mbot-benefits__title-1': {
    en: `Mass Processing:`,
    uk: `Масова обробка:`,
  },
  'mbot-benefits__discription-1': {
    en: `Handle large volumes of documents quickly and efficiently.`,
    uk: `Швидка та ефективна обробка великих обсягів документів.`,
  },
  'mbot-benefits__title-2': {
    en: `Format Support:`,
    uk: `Підтримка форматів:`,
  },
  'mbot-benefits__discription-2': {
    en: `Compatible with most common document formats.`,
    uk: `Сумісний з найпоширенішими форматами документів.`,
  },
  'mbot-benefits__title-3': {
    en: `Data Validation:`,
    uk: `Валідація даних:`,
  },
  'mbot-benefits__discription-3': {
    en: `Built-in validation ensures data accuracy.`,
    uk: `Вбудована валідація забезпечує точність даних.`,
  },
  'mbot-benefits__title-4': {
    en: `ERP Integration:`,
    uk: `Інтеграція з ERP:`,
  },
  'mbot-benefits__discription-4': {
    en: `Seamlessly link extracted data with your ERP system.`,
    uk: `Безшовне з'єднання вилучених даних з вашою ERP системою.`,
  },
  'mbot-benefits__title-5': {
    en: `Workflow Integration:`,
    uk: `Інтеграція в робочі процеси:`,
  },
  'mbot-benefits__discription-5': {
    en: `Easily integrate into your existing workflows.`,
    uk: `Легко інтегрується у ваші існуючі робочі процеси.`,
  },
  'mbot-benefits__title-6': {
    en: `Cost-Effective: `,
    uk: `Ефективність витрат:`,
  },
  'mbot-benefits__discription-6': {
    en: `Pricing based on the number of documents processed.`,
    uk: `Вартість залежить від кількості оброблених документів.`,
  },
  'mbot-benefits__title-7': {
    en: `QR code scanning: `,
    uk: `Зчитування QR кодів:`,
  },
  'mbot-benefits__discription-7': {
    en: `M-BOT can scan and extract information from QR codes in your invoices`,
    uk: `M-BOT здатний сканувати та отримувати інформацію з QR кодів в ваших рахунках`,
  },
  'mbot-benefits__title-8': {
    en: `M-BOT File Manager: `,
    uk: `M-BOT Файловий Менеджер:`,
  },
  'mbot-benefits__discription-8': {
    en: `Ease of managing multiple invoices simultaneously in a streamlined table format.`,
    uk: `Зручність одночасного оброблення кількох рахунків-фактур у впорядкованому табличному форматі.`,
  },
  'mbot-integration__title': {
    en: `Ready for ISV Model with Your Platform & Partner Network`,
    uk: `M-BOT як партнер ISV для вашої CRM платформи`,
  },
  'mbot-integration-description-1': {
    en: `We are ready to cooperate on the ISV model with your platform and
         partner network.`,
    uk: `Ми готові співпрацювати за моделлю ISV з вашою платформою та
        партнерською мережею.`,
  },
  'mbot-integration-description-2': {
    en: `M-BOT is designed for flexibility and easy integration into your CRM
         the platform`,
    uk: `M-BOT розроблений для гнучкості та легкого інтегрування у вашу CRM
        платформу.`,
  },
  'integration-benefits-title-1': {
    en: `Flexible Integration:`,
    uk: `Гнучка інтеграція:`,
  },
  'integration-benefits-text-1': {
    en: `Easily integrate M-BOT into your CRM platform.`,
    uk: `Легко інтегруйте M-BOT у вашу CRM платформу.`,
  },
  'integration-benefits-title-2': {
    en: `Custom API/Connector Adjustments:`,
    uk: `Налаштування API/конекторів:`,
  },
  'integration-benefits-text-2': {
    en: `We can adjust M-BOT to cater to your specific API and connector needs.`,
    uk: ` Ми можемо налаштувати M-BOT відповідно до ваших специфічних потреб API
          та конекторів.`,
  },
  'integration-benefits-title-3': {
    en: `Comprehensive Documentation`,
    uk: `Докладна документація:`,
  },
  'integration-benefits-text-3': {
    en: ` Detailed documentation available for your technical team.`,
    uk: `Детальна документація доступна для вашої технічної команди.`,
  },
  'integration-benefits-title-4': {
    en: `Partner Network Support:`,
    uk: `Підтримка партнерської мережі:`,
  },
  'integration-benefits-text-4': {
    en: `Support for your CRM platform, our customers, and partner network.`,
    uk: `Підтримка вашої CRM платформи, наших клієнтів та партнерської мережі.`,
  },
  'mbot-process-title': {
    en: `How M-BOT works`,
    uk: `Як працює M-BOT`,
  },
  'mbot-process-description-1': {
    en: `M-Bot can simultaneously work with several invoices, even if the source files are in different formats.`,
    uk: ` M-Bot може одночасно працювати з кількома рахунками-фактурами, навіть якщо вихідні файли мають різні формати.`,
  },
  'mbot-process-description-2': {
    en: `M-BOT can validate extracted data to ensure the highest possible accuracy.`,
    uk: `M-BOT може проводити валідацію вилучених даних для забезпечення найвищої можливої точності.`,
  },
  'mbot-process-stages-title': {
    en: `Main stages:`,
    uk: `Основні етапи:`,
  },
  'process-stages-item-1': {
    en: `Document Capture`,
    uk: `Захоплення документів`,
  },
  'process-stages-item-2': {
    en: `Document Processing`,
    uk: `Обробка документів `,
  },
  'process-stages-item-3': {
    en: `Data Extraction (Parsing)`,
    uk: `Витягування даних (парсинг)`,
  },
  'process-stages-item-4': {
    en: `Data Validation`,
    uk: `Валідація даних`,
  },
  'process-stages-item-5': {
    en: `Information Interaction`,
    uk: `Каталогізація та класифікація`,
  },
  'process-stages-item-6': {
    en: `Data Integration`,
    uk: `Інтеграція даних до існуючих систем`,
  },
  'mbot__description-mobile-menu': {
    en: `Automated data extraction from your documents with the latest AI technologies`,
    uk: `Автоматизоване вилучення даних з ваших документів за допомогою новітніх технологій AI`,
  },
  'mbot__description-drop-menu': {
    en: `Automated data extraction from your documents with the latest AI technologies`,
    uk: `Автоматизоване вилучення даних з ваших документів за допомогою новітніх технологій AI`,
  },
  '': {
    en: ``,
    uk: ``,
  },
  '': {
    en: ``,
    uk: ``,
  },
};
